// src/pages/Fantasy/BestSingleTeamPicksPage.tsx

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { currentSeason, divisionMappingsSimpled } from "../../Constants";
import { FantasyPickDTO } from "../../models/FantasyPickDTO";
import { getTopSingleTeamPicks } from "../../services/FantasyService";

const BestSingleTeamPicksPage: React.FC = () => {
  // 1) figure out the season from URL param or fallback to currentSeason
  const { season: seasonParam } = useParams<{ season?: string }>();
  const [season] = useState(
    () => (seasonParam ? parseInt(seasonParam, 10) : currentSeason)
  );

  // 2) local states
  const [division, setDivision] = useState<string>("ligaen"); // default division
  const [picks, setPicks] = useState<FantasyPickDTO[]>([]);
  const [filteredPicks, setFilteredPicks] = useState<FantasyPickDTO[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // We’ll store any error message, but we won't show it in red or with a retry.
  // We'll treat it as if there are no picks.
  const [error, setError] = useState("");

  // 3) fetch data whenever `division` or `season` changes
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError("");
      try {
        const result = await getTopSingleTeamPicks(season, division, 10);
        // sort them descending by totalPoints
        result.sort((a, b) => b.totalPoints - a.totalPoints);

        setPicks(result);
        setFilteredPicks(result);
      } catch (err) {
        // If error, set it—but we won't show a big error message, just no data
        setError(err instanceof Error ? err.message : String(err));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [season, division]);

  // 4) search logic
  useEffect(() => {
    const lowerCaseSearch = searchQuery.toLowerCase();
    const newFiltered = picks.filter((pick) => {
      const playerName = pick.playerAccount?.playerName?.toLowerCase() || "";
      const pickedSteamId = pick.pickedSteamId.toLowerCase();

      return (
        playerName.includes(lowerCaseSearch) ||
        pickedSteamId.includes(lowerCaseSearch)
      );
    });
    setFilteredPicks(newFiltered);
  }, [searchQuery, picks]);

  // 5) Render
  if (isLoading) {
    return <Loading />;
  }

  // We treat an error the same as "no picks" scenario
  // i.e. if there's an error, or if picks.length == 0 => show "No picks found" message
  const noDataFound =
    !!error || filteredPicks.length === 0;

  return (
    <div className="container text-center">
      <h1>Best Single-Team Picks</h1>
      <p>Season {season}</p>

      {/* Division dropdown */}
      <div className="mb-3">
        <label htmlFor="divisionDropdown" className="form-label">
          Select Division
        </label>
        <select
          id="divisionDropdown"
          className="form-select"
          value={division}
          onChange={(e) => setDivision(e.target.value)}
        >
          {Array.from(divisionMappingsSimpled.entries()).map(([key, label]) => (
            <option key={key} value={key}>
              {label}
            </option>
          ))}
        </select>
      </div>

      {/* Search box */}
      <div className="mb-3">
        <input
          className="form-control"
          placeholder="Search picks by player name or SteamID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <hr />

      {noDataFound ? (
        <p>No picks found for {division} in Season {season}.</p>
      ) : (
        <div className="row">
          {filteredPicks.map((pick, index) => {
            const playerName =
              pick.playerAccount?.playerName || pick.pickedSteamId;
            const ownerName =
              pick.ownerAccount?.playerName || pick.ownerSteamId;

            return (
              <div
                className="col-12 col-md-4 mb-3"
                key={`${pick.pickedSteamId}-${pick.fantasyTeamId}`}
              >
                <div className="card">
                  <div className="card-body">
                    <h5>
                      {index + 1}. {playerName}
                    </h5>
                    <p>Total Points: {pick.totalPoints}</p>
                    <p>
                      Owned by: <strong>{ownerName}</strong>
                    </p>

                    {/* Link to the owner's fantasy team */}
                    <Link
                      to={`/fantasy/view/${pick.ownerSteamId}/${season}/${division}`}
                      className="btn btn-primary"
                    >
                      View Owner's Team
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BestSingleTeamPicksPage;
