// src/hooks/useHasPrediction.ts
import { useEffect, useState } from "react";
import { currentSeason } from "../Constants";
import { hasPrediction } from "../services/MatchService";

export default function useHasPrediction(steamId?: string): boolean {
  const [hasPredicted, setHasPredicted] = useState<boolean>(false);

  useEffect(() => {
    if (!steamId) return;
    // Check for predictions using the hasPrediction service function
    hasPrediction(steamId, currentSeason)
      .then(result => setHasPredicted(result))
      .catch(error => {
        console.error("Error checking prediction status:", error);
        setHasPredicted(false);
      });
  }, [steamId]);

  return hasPredicted;
}
