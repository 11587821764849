import axios from 'axios';
import { baseURL } from '../Constants';
import { PlayerStats } from '../models/PlayerStats';

const url = `${baseURL}/PlayerStat`;
const fantasyUrl = `${baseURL}/Fantasy`;

export const getPlayersByTeamId = async (teamId: string, season: number): Promise<PlayerStats[]> => {
    try {
        const response = await axios.get(`${url}/getPlayersByTeamId`, { params: { teamid: teamId, season } });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getFantasyPlayersByTeamId = async (teamId: string, season: number): Promise<PlayerStats[]> => {
    try {
        const response = await axios.get(`${fantasyUrl}/getPlayersByTeamId`, { params: { teamid: teamId, season } });
        return response.data;
    } catch (error) {
        throw error;
    }
};
