export const baseURL = process.env.REACT_APP_API_URL + "/api";

export const divisionMappings: Map<string, string> = new Map([
    ["ligaen", "Ligaen"],
    ["1div", "1 Division"],
    ["2divA", "2 Division A"],
    ["2divB", "2 Division B"],
    ["3divA", "3 Division A"],
    ["3divB", "3 Division B"],
    ["3divC", "3 Division C"],
    ["3divD", "3 Division D"],
    ["4divA", "4 Division A"],
    ["4divB", "4 Division B"],
    ["4divC", "4 Division C"],
    ["4divD", "4 Division D"],
    ["5divA", "5 Division A"],
    ["5divB", "5 Division B"]
]);

export const divisionMappingsSimple: Map<string, string> = new Map([
    ["ligaen", "Ligaen"],
    ["1div", "1 Division"],
    ["2div", "2 Division"],
    ["3div", "3 Division"],
    ["4div", "4 Division"],
    ["5div", "5 Division"],
]);

export const divisionMappingsSimpled: Map<string, string> = new Map([
    ["ligaen", "Ligaen"],
    ["1div", "1 Division"],
    ["2div", "2 Division"],
    ["3div", "3 Division"],
    ["4div", "4 Division"],
]);

export const simpleDivisionMappings: Map<string, string> = new Map([
    ["1", "Ligaen"],
    ["2", "1 Division"],
    ["3", "2 Division"],
    ["4", "3 Division"],
    ["5", "4 Division"],
    ["6", "5 Division"]
]);


export const mapMappings: Map<string, string> = new Map([
    ["de_dust2", "Dust 2"],
    ["de_inferno", "Inferno"],
    ["de_mirage", "Mirage"],
    ["de_nuke", "Nuke"],
    ["de_overpass", "Overpass"],
    ["de_vertigo", "Vertigo"],
    ["de_ancient", "Ancient"],
    ["de_anubis", "Anubis"],
    ["de_train", "Train"],
]);

export const roles = ["IGL", "Entry Fragger", "Support", "AWPer", "Lurker", "Rifler", "Coach", "Anchor"];

export const currentSeason = 28;

//export const currentStandingSeason = 27;
export const trophyUsers = [
    "76561198023098234",
    "76561198869052985",
    "76561199123600668",
    "76561198248762732",
    "76561198036882864"
];

export const admins = [76561198269061841, 76561198125971713]
export const playerImages = [
    "6561199066108638",
    "76561197981010839",
    "76561197998469938",
    "76561198005496143",
    "76561198047652906",
    "76561198054946724",
    "76561198059322915",
    "76561198059636829",
    "76561198074859008",
    "76561198075693756",
    "76561198075772339",
    "76561198081246043",
    "76561198082225391",
    "76561198124136504",
    "76561198125971713",
    "76561198138532620",
    "76561198141477673",
    "76561198149417977",
    "76561198157504520",
    "76561198160006089",
    "76561198163517386",
    "76561198176005534",
    "76561198183348569",
    "76561198200697887",
    "76561198201191395",
    "76561198201496594",
    "76561198203780216",
    "76561198219555624",
    "76561198233292524",
    "76561198258148023",
    "76561198261133739",
    "76561198262758303",
    "76561198278140719",
    "76561198280530766",
    "76561198282538057",
    "76561198297876304",
    "76561198301442273",
    "76561198304901397",
    "76561198313091647",
    "76561198342818190",
    "76561198345015789",
    "76561198361899075",
    "76561198365854684",
    "76561198371339090",
    "76561198388382195",
    "76561198397764409",
    "76561198401642558",
    "76561198432023000",
    "76561198445123150",
    "76561198447521195",
    "76561198801417447",
    "76561198830173768",
    "76561198842197798",
    "76561198845508560",
    "76561198881189893",
    "76561198905721997",
    "76561198981175481",
    "76561198984376510",
    "76561199033760198",
    "76561199070740200",
    "76561199187413982"
]
export const betaUsers = [
    "76561198269061841",
    "76561198125971713",
    "76561198019509008",
    "76561198082225391",
    "76561197982707133",
    "76561197970395883",
    "76561198201191395",
    "76561198280530766",
    "76561199122348312",
    "76561198277353799",
    "76561197983673093",
    "76561198344216742",
    "76561198128064229",
    "76561198201496594",
    "76561198305405206",
    "76561198013173057",
    "76561198848242343",
    "76561198149417977",
    "76561198258148023",
    "76561198081246043",
    "76561198176005534",
    "76561198265857122",
    "76561198045548888",
    "76561198074859008",
    "76561198149417977",
    "76561197983673093"
];


