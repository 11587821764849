import React, { useState, useEffect, useCallback, useRef } from 'react';
import { getDivisionStandings, getAllUserPredictions } from "../services/MatchService";
import { Standing } from "../models/Standing";
import { divisionMappings } from "../Constants";
import useLocalStorage from 'use-local-storage';

interface AllDivisionPredictionsProps {
  season: number;
  steamId: string;
}

interface TeamPrediction {
  teamId: string;
  predictedRank: number;
}

interface StandingsPrediction {
  division: string;
  teams: TeamPrediction[];
}

interface TeamDisplay {
  teamId: string;
  teamName: string;
  teamLogo: string;
  predictedRank: number;
  actualRank?: number;
  division: string;
}

const AllDivisionPredictions: React.FC<AllDivisionPredictionsProps> = ({ season, steamId }) => {
  const [isDark] = useLocalStorage<boolean>('isDark', false);
  const [displayPredictions, setDisplayPredictions] = useState<TeamDisplay[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const hasFetched = useRef(false);

  const getStandingColor = useCallback((divisionKey: string, index: number, isDark: boolean) => {
    // Default to the current row's background color instead of white
    let color = isDark
      ? index % 2 === 0 ? '#2C3034' : '#212529'
      : index % 2 === 0 ? '#F2F2F2' : '#FFFFFF';

    if (season === 28) {
      if (divisionKey === "ligaen") {
        if (index < 4) color = "green";
        else if (index === 7) color = "orange";
        else if (index === 8) color = "red";
      } else if (
        String(divisionKey).startsWith("2div") ||
        divisionKey.endsWith("div")
      ) {
        if (index === 0) color = "green";
        else if (index === 1) color = "yellow";
        else if (index === 6 || index === 7) color = "orange";
        else if (index === 8 || index === 9 || index === 10) color = "red";
      } else if (String(divisionKey).startsWith("3div")) {
        if (index === 0) color = "green";
        else if (index >= 1 && index <= 2) color = "yellow";
        else if (index >= 5 && index <= 7) color = "orange";
        else if (index >= 8 && index <= 9) color = "red";
      } else if (String(divisionKey).startsWith("4div")) {
        if (index === 0 || index === 1) {
          color = "green";
        } else if (index >= 2 && index <= 4) {
          color = "yellow";
        }
      }
    }
    return color;
  }, [season]);

  const renderTable = useCallback((teams: TeamDisplay[]) => (
    <div className={`card ${isDark ? 'text-white' : ''}`}
      style={{
        border: isDark ? '1px solid #000' : 'none',
        backgroundColor: isDark ? '#2C3034' : '#fff',
      }}>
      <div className="card-body">
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{
              backgroundColor: isDark ? '#2C3034' : '#f8f9fa',
              color: isDark ? '#fff' : '#000',
              borderBottom: isDark ? '1px solid #555' : '1px solid #ccc',
              height: '45px'
            }}>
              <th style={{
                backgroundColor: '#e25623',
                color: '#fff',
                width: '20%',
                padding: '0.5rem 0.75rem',
              }}>Forventede</th>
              <th style={{
                backgroundColor: '#e25623',
                color: '#fff',
                width: '20%',
                padding: '0.5rem 0.75rem',
              }}>Nuværende</th>
              <th style={{
                backgroundColor: '#e25623',
                color: '#fff',
                width: '50%',
                padding: '0.5rem 0.75rem',
              }}>Hold</th>
              <th style={{
                backgroundColor: '#e25623',
                color: '#fff',
                width: '10%',
                padding: '0.5rem 0.75rem',
                textAlign: 'center'
              }}>Logo</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team, idx) => {
              const rowBackground = isDark
                ? idx % 2 === 0 ? '#2C3034' : '#212529'
                : idx % 2 === 0 ? '#F2F2F2' : '#FFFFFF';

              return (
                <tr key={team.teamId}
                  style={{
                    backgroundColor: rowBackground,
                    color: isDark ? '#fff' : '#000',
                    borderBottom: isDark ? '1px solid #555' : '1px solid #ccc',
                    height: '45px'
                  }}>
                  <td style={{ 
                    padding: '0.5rem 0.75rem',
                    backgroundColor: getStandingColor(team.division, team.predictedRank - 1, isDark)
                  }}>
                    {team.predictedRank}
                  </td>
                  <td style={{ 
                    padding: '0.5rem 0.75rem',
                    backgroundColor: rowBackground  // Using row background instead of standing color
                  }}>
                    {team.actualRank ?? "-"}
                  </td>
                  <td style={{ padding: '0.5rem 0.75rem' }}>
                    {team.teamName}
                  </td>
                  <td style={{ padding: '0.5rem 0.75rem', textAlign: "center" }}>
                    {team.teamLogo ? (
                      <img
                        src={team.teamLogo}
                        alt={team.teamName}
                        style={{
                          width: 25,
                          height: 25,
                          objectFit: "contain",
                          verticalAlign: "middle",
                        }}
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ), [getStandingColor, isDark]);
  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    const loadPredictionsAndStandings = async () => {
      try {
        setLoading(true);
        console.log(`[AllDivisionPredictions] Loading predictions for ${steamId}, season ${season}`);
        
        const userPredictions = await getAllUserPredictions(steamId, season);
        console.log('Fetched userPredictions:', userPredictions);

        const allPredictions: TeamDisplay[] = [];
        
        const predictionsByDivision = userPredictions.reduce((acc, prediction) => {
          if (!acc[prediction.division]) acc[prediction.division] = [];
          acc[prediction.division].push(prediction);
          return acc;
        }, {} as Record<string, StandingsPrediction[]>);

        for (const division in predictionsByDivision) {
          console.log(`[AllDivisionPredictions] Fetching standings for division ${division}`);
          const divisionStandings = await getDivisionStandings(season, division);
          const firstKey = Object.keys(divisionStandings)[0] || "";
          const standingArr: Standing[] = divisionStandings[firstKey] || [];

          const actualRankMap = new Map(standingArr.map((s) => [s.team.teamId, s.rank]));
          const teamInfoMap = new Map(standingArr.map((s) => [
            s.team.teamId,
            { teamName: s.team.teamName, teamLogo: s.team.teamLogo },
          ]));

          predictionsByDivision[division].forEach(predictionList => {
            predictionList.teams.forEach(pred => {
              const teamInfo = teamInfoMap.get(pred.teamId);
              if (teamInfo) {
                allPredictions.push({
                  teamId: pred.teamId,
                  teamName: teamInfo.teamName,
                  teamLogo: teamInfo.teamLogo,
                  predictedRank: pred.predictedRank,
                  actualRank: actualRankMap.get(pred.teamId),
                  division: division,
                });
              }
            });
          });
        }

        setDisplayPredictions(allPredictions);
        setError(null);
      } catch (err) {
        console.error("Error loading predictions:", err);
        setError(err instanceof Error ? err.message : "Error loading predictions");
      } finally {
        setLoading(false);
      }
    };

    loadPredictionsAndStandings();

    return () => {
      console.log("[AllDivisionPredictions] Cleanup on unmount or dependency change.");
    };
  }, [season, steamId]);

  if (loading) return <div>Indlæser...</div>;
  if (error) return <div className="text-danger">{error}</div>;
  if (displayPredictions.length === 0) return <div>Ingen predictions fundet</div>;

  const predictionsByDivision: Record<string, TeamDisplay[]> = {};
  displayPredictions.forEach(pred => {
    if (!predictionsByDivision[pred.division]) {
      predictionsByDivision[pred.division] = [];
    }
    predictionsByDivision[pred.division].push(pred);
  });

  Object.values(predictionsByDivision).forEach(divPredictions => {
    divPredictions.sort((a, b) => a.predictedRank - b.predictedRank);
  });

  const formatDivisionName = (div: string) => {
    return divisionMappings.get(div) || div;
  };

  const divisions = Object.keys(predictionsByDivision).sort((a, b) => {
    const aNum = parseInt(a.match(/\d+/)?.[0] || '0');
    const bNum = parseInt(b.match(/\d+/)?.[0] || '0');
    if (aNum !== bNum) return aNum - bNum;
    return a.localeCompare(b);
  });

  const divisionPairs = [];
  for (let i = 0; i < divisions.length; i += 2) {
    divisionPairs.push(divisions.slice(i, i + 2));
  }

  return (
    <div className={`col-12 ${isDark ? 'text-white' : ''}`}>
      <h3>Predictions for sæson {season}</h3>
      {divisionPairs.map((pair, index) => (
        <div key={index} className="mb-4">
          <div className="row gx-4">
            {pair.map((div) => (
              <div key={div} className="col-12 col-sm-12 col-md-6 mb-4">
                <h4>{formatDivisionName(div)}</h4>
                {renderTable(predictionsByDivision[div])}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllDivisionPredictions;