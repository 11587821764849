import React from 'react';
import { baseURL, playerImages } from '../Constants';

type PlayerImageProps = {
    id: string;
    width?: number;
};

const PlayerImage = ({ id, width = 100 }: PlayerImageProps) => {
    const imageSrc = playerImages.includes(id)
        ? `${baseURL}/Account/Profile/${id}?width=${width}`
        : `https://report.dpl.gg/powerfiles/ligaen/getPlayerPicture.php?id=${id}&width=${width}`;

    return (
        <img
            src={imageSrc}
            alt="Player"
            style={{ width, height: "auto" }}
            loading="lazy"
            onError={(e) => {
                // Fallback to the external URL if the local image fails
                const target = e.target as HTMLImageElement;
                if (!target.src.includes('dpl.gg')) {
                    target.src = `https://report.dpl.gg/powerfiles/ligaen/getPlayerPicture.php?id=${id}&width=${width}`;
                }
            }}
        />
    );
};

export default PlayerImage;