import { Link } from "react-router-dom";
import { UpcomingMatch } from "../models/UpcomingMatch";
import { FC, useState, useMemo } from "react";
import useLocalStorage from 'use-local-storage';

interface UpcomingMatchTableProps {
  matchesForDate: UpcomingMatch[];
  season?: number; // Make season optional with '?'
}

const UpcomingMatchTable: FC<UpcomingMatchTableProps> = ({ matchesForDate, season }) => {
  const [isDark] = useLocalStorage<boolean>("isDark", false);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'ascending' | 'descending';
  }>({
    key: '',
    direction: 'ascending',
  });

  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedMatches = useMemo(() => {
    const sortedData = [...matchesForDate];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        let aValue: any;
        let bValue: any;

        switch (sortConfig.key) {
          case 'team1':
            aValue = a.team1.teamName || '';
            bValue = b.team1.teamName || '';
            break;
          case 'team2':
            aValue = a.team2.teamName || '';
            bValue = b.team2.teamName || '';
            break;
          case 'match_time':
            aValue = new Date(a.match_time).getTime();
            bValue = new Date(b.match_time).getTime();
            break;
          default:
            return 0;
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedData;
  }, [matchesForDate, sortConfig]);

  // Helper function to build team link (with or without ?season=...)
  const buildTeamLink = (teamId: string) => {
    if (season !== undefined) {
      return `/team/${teamId}?season=${season}`;
    }
    return `/team/${teamId}`;
  };

  return (
    <div className={`text-center ${isDark ? 'text-light' : ''}`}>
      <table
        className={`table table-striped ${isDark ? 'table-dark' : ''}`}
        style={
          isDark
            ? {
                border: '1px solid #111',
                borderCollapse: 'collapse' as 'collapse',
              }
            : undefined
        }
      >
        <thead>
          <tr>
            <th
              style={{ width: '35%', cursor: 'pointer' }}
              onClick={() => handleSort('team1')}
            >
              Hold 1{" "}
              {sortConfig.key === 'team1' &&
                (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th
              style={{ width: '30%', cursor: 'pointer' }}
              onClick={() => handleSort('match_time')}
            >
              VS{" "}
              {sortConfig.key === 'match_time' &&
                (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th
              style={{ width: '35%', cursor: 'pointer' }}
              onClick={() => handleSort('team2')}
            >
              Hold 2{" "}
              {sortConfig.key === 'team2' &&
                (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedMatches.map((match, index) => (
            <tr key={index} style={{ height: '80px' }}>
              <td className="align-middle">
                {/* Link includes ?season=... only if season is passed */}
                <Link
                  to={buildTeamLink(match.team1.teamId)}
                  className={`text-decoration-none ${
                    isDark ? 'text-light' : 'text-black'
                  } d-flex flex-column align-items-center`}
                >
                  <img
                    src={match.team1.teamLogo}
                    alt={match.team1.teamName}
                    style={{ width: 40, height: 40, objectFit: 'contain' }}
                    loading="lazy"
                  />
                  <span
                    className="mt-1 text-truncate"
                    style={{ maxWidth: '100%' }}
                  >
                    {match.team1.teamName}
                  </span>
                </Link>
              </td>
              <td className="align-middle text-center" style={{ fontSize: '1.5rem' }}>
                <div>
                  {new Date(match.match_time).toLocaleTimeString('da-DK', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })}
                </div>
                {/* Button to navigate to PreMatchPage */}
                <Link to={`/prematch/${match.challonge_id}`} className="btn btn-primary mt-2">
                  View Match
                </Link>
              </td>
              <td className="align-middle">
                {/* Link includes ?season=... only if season is passed */}
                <Link
                  to={buildTeamLink(match.team2.teamId)}
                  className={`text-decoration-none ${
                    isDark ? 'text-light' : 'text-black'
                  } d-flex flex-column align-items-center`}
                >
                  <img
                    src={match.team2.teamLogo}
                    alt={match.team2.teamName}
                    style={{ width: 40, height: 40, objectFit: 'contain' }}
                    loading="lazy"
                  />
                  <span
                    className="mt-1 text-truncate"
                    style={{ maxWidth: '100%' }}
                  >
                    {match.team2.teamName}
                  </span>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UpcomingMatchTable;
