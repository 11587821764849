import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { Match } from "../../models/Match";
import { getMatchesForPlayerBySeason } from "../../services/MatchService";
import MatchTable from "../../components/MatchTabel";
import { fetchSeasonsPlayed } from "../../services/PlayerStatService";
import SeasonScroll from "../../components/SeasonScroll/SeasonScroll";
import Loading from "../../components/Loading";

const PlayerMatch = () => {
  const { steamId } = useParams<{ steamId: string }>();
  const [matches, setMatches] = useState<Match[]>([]);
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState<number[]>([]);
  const [season, setSeason] = useState<number>(0);

  // Fetch which seasons the player has played
  const fetchPlayedSeasons = useCallback(async () => {
    if (!steamId) return;
    setLoading(true);
    try {
      const fetchedSeasons = await fetchSeasonsPlayed(steamId);
      // Sort descending (highest season first)
      const sortedSeasons = fetchedSeasons.sort((a, b) => b - a);
      setSeasons(sortedSeasons);

      // Default to the highest season
      if (sortedSeasons.length > 0) {
        setSeason(sortedSeasons[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [steamId]);

  // On component mount, fetch all played seasons
  useEffect(() => {
    fetchPlayedSeasons();
  }, [fetchPlayedSeasons]);

  // Whenever the selected season changes, fetch that season’s matches
  useEffect(() => {
    if (!steamId || !season) return;
    setLoading(true);
    const fetchMatches = async () => {
      try {
        const fetchedMatches = await getMatchesForPlayerBySeason(steamId, season);
        setMatches(fetchedMatches);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchMatches();
  }, [season, steamId]);

  return (
    <div>
      {/* Only render the SeasonScroll if we have seasons */}
      {seasons.length > 0 && (
        <SeasonScroll
          seasons={seasons}
          selectedSeason={season}
          onSeasonChange={setSeason}
        />
      )}

      {/* If loading is true, show a spinner. Otherwise show the MatchTable. */}
      {loading ? (
        <Loading />
      ) : (
        // Pass 'season' as an optional prop.
        // If your MatchTable supports "season?: number", it will
        // include ?season=... in team links if the season is defined.
        <MatchTable matches={matches} compact={false} season={season} />
      )}
    </div>
  );
};

export default PlayerMatch;
