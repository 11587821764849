import axios from "axios";
import { baseURL } from "../Constants";
import { Match } from "../models/Match";
import { MapStats } from "../models/MapStats";
import { UpcomingMatch } from "../models/UpcomingMatch";
import { TeamPrediction } from "../models/TeamPrediction";
import { StandingsPrediction } from "../models/StandingsPrediction";
import { Standing } from "../models/Standing";

const url = `${baseURL}/Match`;

export const createStandingsPrediction = async (
    steamId: string,
    season: number,
    division: string,
    teams: TeamPrediction[]
): Promise<number> => {
    try {
        const response = await axios.post(`${baseURL}/StandingsPrediction/create`, {
            steamId,
            season,
            division,
            teams
        });
        return response.data.predictionId;
    } catch (error) {
        throw error;
    }
};

export const updateStandingsPrediction = async (
    steamId: string,
    season: number,
    division: string,
    teams: TeamPrediction[]
): Promise<void> => {
    try {
        // Changed to use POST method to match backend endpoint
        await axios.post(`${baseURL}/StandingsPrediction/edit`, {
            steamId,
            season,
            division,
            teams
        });
    } catch (error) {
        console.error('Error updating prediction:', error);
        throw error;
    }
};

export const getStandingsPrediction = async (
    steamId: string,
    season: number,
    division: string
): Promise<StandingsPrediction | null> => {
    try {
        const response = await axios.get(
            `${baseURL}/StandingsPrediction/get?steamId=${steamId}&season=${season}&division=${division}`
        );
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            return null;
        }
        throw error;
    }
};

export async function hasPrediction(steamId: string , season: number): Promise<boolean> {
    try {
      const response = await axios.get(
        `${baseURL}/StandingsPrediction/hasPrediction?steamId=${steamId}&season=${season}`
      );
      return response.data; 
      // Expecting a boolean response from the server
    } catch (error) {
      console.error("Error checking for prediction:", error);
      throw error; 
    }
  }

export const getAllUserPredictions = async (
    steamId: string,
    season?: number,
    division?: string
 ): Promise<StandingsPrediction[]> => {
    try {
        const params = new URLSearchParams();
        params.append('steamId', steamId);
        if (season) params.append('season', season.toString());
        if (division) params.append('division', division);
 
        const response = await axios.get(`${baseURL}/StandingsPrediction/getByUser?${params}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user predictions:', error);
        return [];
    }
 };

export const getAllStandingsPredictions = async (
    season?: number,
    division?: string
): Promise<StandingsPrediction[]> => {
    try {
        let url = `${baseURL}/StandingsPrediction/getAll`;
        const params = new URLSearchParams();
        if (season) params.append('season', season.toString());
        if (division) params.append('division', division);
        
        if (params.toString()) {
            url += `?${params.toString()}`;
        }
        
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getUpcomingMatchById = async (id: string): Promise<UpcomingMatch> => {
    try {
        const response = await axios.get(`${url}/getUpcomingMatchById/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getLatestMatches = async (page: number): Promise<Match[]> => {
    try {
        const response = await axios.get(`${url}/latest?page=${page}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getLatestSeason = async (): Promise<number> => {
    try {
        const response = await axios.get(`${url}/latestSeason`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getUpcomingMatches = async (): Promise<UpcomingMatch[]> => {
    try {
        const response = await axios.get(`${url}/getUpcomingMatches`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getUpcomingMatchesForTeam = async (teamId: string): Promise<UpcomingMatch[]> => {
    try {
        const response = await axios.get(`${url}/getUpcomingMatchesForTeam?teamId=${teamId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getOldestSeason = async (): Promise<number> => {
    try {
        const response = await axios.get(`${url}/oldestSeason`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getMatchesForTeamBySeason = async (teamId: string, season: number): Promise<Match[]> => {
    try {
        const response = await axios.get(`${url}/team`, { params: { teamId, season } });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getVetoStatsForTeamBySeason = async (teamId: string, season: string): Promise<MapStats[]> => {
    try {
        const response = await axios.get(`${url}/getVetoStats`, { params: { teamid: teamId, season } });
        return response.data.map((item: { mapName: string; amountPicked: number; amountBanned: number; amountPlayed: number; amountWon: number; chosenCTPercentage: number; }) => new MapStats(
            item.mapName,
            item.amountPicked,
            item.amountBanned,
            item.amountPlayed,
            item.amountWon,
            item.chosenCTPercentage,
        ));
    }
    catch (error) {
        throw error;
    }
}

export const getMatchesForPlayerBySeason = async (steamId: string, season: number): Promise<Match[]> => {
    try {
        const response = await axios.get(`${url}/player`, { params: { steamId, season } });
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getMatchById = async (matchId: string): Promise<Match[]> => {
    try {
        const response = await axios.get(`${url}/${matchId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getTeamStanding = async (
    teamId: string,
    season: number
  ): Promise<Standing> => {
    try {
      const response = await axios.get(
        `${url}/standingForTeam?teamId=${teamId}&season=${season}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const getDivisionStandingsAll = async (season: number) => {
    try {
        const response = await axios.get(`${url}/getAllDivisionStandings?season=${season}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getDivisionStandings = async (season: number, division: string) => {
    try {
        const response = await axios.get(`${url}/getAllDivisionStandings?season=${season}&majorDivision=${division}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getDivisionsBySeason = async (season: number): Promise<string[]> => {
    try {
        const response = await axios.get(`${url}/getDivisionsBySeason?season=${season}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}

export const getPlayersAvgRating = async (matchId: string): Promise<{ [steamId: string]: number }> => {
    try {
        const response = await axios.get(`${url}/getPlayersAvgRating?matchId=${matchId}`);
        return response.data;
    }
    catch (error) {
        throw error;
    }
}
