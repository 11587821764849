import React, { useState } from "react";
import PlayerImage from "../../components/PlayerImage";
import { FantasyPlayer } from "../../models/FantasyPlayer";
import { FantasyRarity } from "../../models/FantasyRarity";
import styles from "./PlayerSlot.module.css";

interface ReadOnlyPlayerSlotProps {
  player: FantasyPlayer;
}

const ReadOnlyPlayerSlot: React.FC<ReadOnlyPlayerSlotProps> = ({ player }) => {
  const [flipped, setFlipped] = useState(false);

  const handleFlip = () => setFlipped(!flipped);

  const formatCurrency = (amount: number) =>
    new Intl.NumberFormat("da-DK", {
      style: "currency",
      currency: "DKK",
      minimumFractionDigits: 0,
    }).format(amount);

  const getRarityClass = () => {
    switch (player.rarity) {
      case FantasyRarity.ShinyGold:
        return styles.ShinyGold;
      case FantasyRarity.Gold:
        return styles.Gold;
      case FantasyRarity.ShinySilver:
        return styles.ShinySilver;
      case FantasyRarity.Silver:
        return styles.Silver;
      case FantasyRarity.Bronze:
        return styles.Bronze;
      default:
        return styles.Gray; // Default class for unknown rarities
    }
  };

  const getShinyEffectClass = () => {
    return player.rarity === FantasyRarity.ShinyGold || player.rarity === FantasyRarity.ShinySilver
      ? styles.shinyEffect
      : "";
  };

  return (
    <div
      className={`card ${styles.flipCard} ${getRarityClass()} ${
        flipped ? styles.flipped : ""
      }`}
      style={{
        minHeight: "290px",
        maxWidth: "220px",
        maxHeight: "290px",
        cursor: "pointer", // Indicate clickable card
      }}
      onClick={handleFlip}
    >
      <div className={styles.flipCardInner}>
        {/* Front Side */}
        <div className={`${styles.flipCardFront}`}>
          <div
            className="card-body"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              padding: "20px 10px",
            }}
          >
            <div
              className={getShinyEffectClass()}
              style={{ marginBottom: "15px" }}
            >
              <PlayerImage id={player.account.steamID} width={180} />
            </div>
            <div
              className="text-center"
              style={{
                marginTop: "0px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h5 className="mb-1">{player.account.playerName}</h5>
            </div>
          </div>
        </div>

        {/* Back Side */}
        <div
          className={`${styles.flipCardBack} d-flex flex-column justify-content-between align-items-center p-3`}
        >
          <h5 className="mb-2">{player.account.playerName}</h5>
          <ul className="list-unstyled text-center mb-1">
            <li>
              <strong>Rating:</strong>{" "}
              {player.stats?.hltv2Rating
                ? player.stats.hltv2Rating.toFixed(2)
                : "N/A"}
            </li>
            <li>
              <strong>K/D:</strong>{" "}
              {player.stats?.kd ? player.stats.kd.toFixed(2) : "N/A"}
            </li>
            <li>
              <strong>First Kills:</strong> {player.stats?.fk ?? "N/A"}
            </li>
            <li>
              <strong>KAST:</strong>{" "}
              {player.stats?.kast ? player.stats.kast.toFixed(2) : "N/A"}%
            </li>
            <li>
              <strong>ADR:</strong>{" "}
              {player.stats?.adr ? player.stats.adr.toFixed(2) : "N/A"}
            </li>
            <li>
              <strong>HS:</strong>{" "}
              {player.stats?.hs ? player.stats.hs.toFixed(2) : "N/A"}%
            </li>
            <li>
              <strong>Faceit Elo:</strong> {player.account.faceitElo ?? "N/A"}
            </li>
          </ul>
          <a
            href={`https://powerstats.dk/player/${player.account.steamID}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary mt-1"
          >
            View Profile
          </a>
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyPlayerSlot;
