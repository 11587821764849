import { Link } from "react-router-dom";
import PlayerImage from "../../components/PlayerImage";
import { PlayerStats } from "../../models/PlayerStats";

interface TeamPlayersProps {
  players: PlayerStats[];
  averageRating: string;
}

const TeamPlayers = ({ players, averageRating }: TeamPlayersProps) => {
  players.sort((a, b) => b.gamesPlayed - a.gamesPlayed);

  return (
    <div className="text-center">
      <h5>Average Rating: {averageRating}</h5>

      {/**
       * Hardcoded custom gap using "gap" inline styles.
       * Bootstrap's "row-cols" handles responsiveness.
       */}
      <div
        className="row justify-content-center"
        style={{ gap: "9px" }} // Custom hardcoded gap (18px)
      >
        {players && players.length > 0 ? (
          players.map((player, index) => (
            <div className="col-auto" key={index}>
              <div
                className="card bg-light text-dark"
                style={{
                  width: "225px",
                  height: "200px",
                  overflow: "hidden",
                }}
              >
                <Link
                  to={`/player/${player.steamID}`}
                  className="text-decoration-none text-black"
                >
                  <div className="card-body text-center">
                    <PlayerImage id={player.steamID} width={100} />
                    <h5 className="card-title">
                      {player.account
                        ? player.account.playerName
                        : player.steamID}
                    </h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      {player.hltv2Rating} - Rating
                    </h6>
                    <h6 className="card-subtitle mb-2 text-muted">
                      {player.gamesPlayed} - Kampe spillet
                    </h6>
                  </div>
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center">
            <p>Der er ingen spillere på holdet.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamPlayers;
