import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PersonSearch, SportsMma, Leaderboard, CompareArrows, EmojiEvents, AttachMoney, SsidChart, GroupAdd } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { baseURL, betaUsers } from "../Constants"; // Import betaUsers
import { AuthContext } from "../context/AuthContext";
import SearchBar from "./SearchBar/SearchBar";
import logo from '../content/logo.png';
import '../styles/theme.css';
import PlayerImage from "./PlayerImage";
import useLocalStorage from "use-local-storage";
import Toggle from "./Toggle/Toggle";

interface Icon {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    displayName: string;
    link: string;
}

const Header = () => {
    const [navLinks, setNavLinks] = useState<Icon[]>([]);
    const { user, setToken } = useContext(AuthContext);
    const [isDark, setIsDark] = useLocalStorage<boolean>("isDark", false);

    useEffect(() => {
        const defaultLinks: Icon[] = [
            {
                icon: SportsMma,
                displayName: "Kampe",
                link: "/matches"
            },
            {
                icon: Leaderboard,
                displayName: "Stillinger",
                link: "/standings"
            },
            {
                icon: PersonSearch,
                displayName: "LFT",
                link: "/lft"
            },
            {
                icon: GroupAdd,
                displayName: "LFP",
                link: "/lfp"
            },
            {
                icon: SsidChart,
                displayName: "Fantasy",
                link: "/fantasy"
            },
            {
                icon: EmojiEvents,
                displayName: "Leaderboard",
                link: "/leaderboard"
            },
            {
                icon: CompareArrows,
                displayName: "Sammenlign",
                link: "/compare"
            },
            {
                icon: AttachMoney,
                displayName: "Hall of Fame",
                link: "/donations"
            }
        ];

        setNavLinks(defaultLinks);
    }, [user]);

    const steamLogin = async () => {
        const url = baseURL + "/Auth/steam";
        window.location.href = url;
    };

    const logout = () => {
        setToken(null);
    };

    // Determine if current user is a beta user
    const isBetaUser = user && betaUsers.includes(user.steamID);

    return (
        <nav className="navbar navbar-expand-lg py-0 mb-3">
            <Link className="navbar-brand ms-3" to="/">
                <img src={logo} alt="logo" width="35" height="35" className="d-inline-block align-text-top me-2" />
                <span className="d-none d-md-inline">PowerStats</span>
            </Link>
            <button className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav mr-auto">
                    {navLinks.map((link, index) => {
                        // Special handling for "Stillinger" based on beta user status
                        if (link.displayName === "Stillinger") {
                                // Render dropdown for beta users
                                return (
                                    <li className="nav-item dropdown m-1" key={index}>
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="standingsDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <link.icon /> {link.displayName}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="standingsDropdown">
                                            <li>
                                                <Link className="dropdown-item" to={link.link}>
                                                    Alle stillinger
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/standings/predict">
                                                    Predicts
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                );
                            } 

                        // Render other links as usual
                        return (
                            <li className="nav-item m-1" key={index}>
                                <Link className="nav-link" to={link.link}>
                                    <link.icon /> {link.displayName}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
                <ul className="navbar-nav ms-auto m-1">
                    <Toggle handleChange={setIsDark} isChecked={isDark} />
                    <SearchBar />
                    {user ? (
                        <div className='dropdown m-2'>
                            <button className='btn btn-primary profile-btn dropdown-bs-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                                <PlayerImage id={user.steamID} width={30} />
                                <div className='d-inline-block align-middle mx-2'>
                                    <span className="navbar-playername"> {user.playerName} </span>
                                </div>
                            </button>
                            <ul className='dropdown-menu'>
                                <li>
                                    <Link className='dropdown-item' to={`/player/${user.steamID}`}>
                                        Min profil
                                    </Link>
                                </li>
                                <li>
                                    <Link className='dropdown-item' to={`/player/${user.steamID}/edit`}>
                                        Rediger profil
                                    </Link>
                                </li>
                                <li>
                                    <button className='dropdown-item' type='button' onClick={logout}>
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <button className="btn btn-default" onClick={steamLogin}>
                            <img src="https://steamcommunity-a.akamaihd.net/public/images/signinthroughsteam/sits_large_noborder.png" alt="steamLogo" />
                        </button>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default Header;
