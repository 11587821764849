import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchTeamByTeamIdAndSeason } from '../services/TeamService';
import { Team } from '../models/Team';
import { Match } from '../models/Match';
import { mapMappings } from '../Constants';
import useLocalStorage from 'use-local-storage';

interface MatchTableProps {
  matches: Match[];
  compact: boolean;
  season?: number; // Make it optional
}

const MatchTable: React.FC<MatchTableProps> = ({ matches, compact, season }) => {
  const [teams, setTeams] = useState<{ [key: string]: Team }>({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isDark] = useLocalStorage<boolean>('isDark', false);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'ascending' | 'descending';
  }>({
    key: 'date', // default sort by date
    direction: 'descending', // most recent first
  });

  // Keep track of window resizing to handle mobile layout changes
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  /**
   * Fetch each team's data by ID (and by season if available).
   * If season isn't passed, we fallback to the season from the first match.
   */
  useEffect(() => {
    // Collect all unique team IDs from the matches that we haven't already fetched
    const teamIdsToFetch: string[] = matches.reduce((ids: string[], match) => {
      if (!teams[match.team1.teamId]) {
        ids.push(match.team1.teamId);
      }
      if (!teams[match.team2.teamId]) {
        ids.push(match.team2.teamId);
      }
      return ids;
    }, []);

    if (teamIdsToFetch.length === 0) {
      return; // Nothing to fetch
    }

    const fetchTeams = async (teamIds: string[]) => {
      try {
        console.debug('fetchTeams called with:', teamIds, '| Passed "season":', season);

        const fetchedTeams: { [key: string]: Team } = {};
        await Promise.all(
          teamIds.map(async (teamId) => {
            if (!teams[teamId]) {
              // If `season` is provided, use that; otherwise, fallback to match's season
              // (assuming all matches share the same season if not explicitly provided)
              const effectiveSeason = season ?? matches[0].season;
              console.debug(
                `\tFetching teamId: ${teamId} with effectiveSeason: ${effectiveSeason}`
              );

              const team = await fetchTeamByTeamIdAndSeason(teamId, effectiveSeason);
              if (team) {
                fetchedTeams[teamId] = team;
              } else {
                console.warn(
                  `\tNo team data returned for teamId:${teamId} & season:${effectiveSeason}`
                );
              }
            }
          })
        );
        setTeams((prevTeams) => ({ ...prevTeams, ...fetchedTeams }));
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
    };

    fetchTeams(teamIdsToFetch);
  }, [matches, teams, season]);

  // Sorting
  const handleSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Sort the matches array using the chosen config
  const sortedMatches = useMemo(() => {
    const sorted = [...matches];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        let aValue: any;
        let bValue: any;

        switch (sortConfig.key) {
          case 'date':
            aValue = new Date(a.match_time).getTime();
            bValue = new Date(b.match_time).getTime();
            break;
          case 'team1':
            aValue = teams[a.team1.teamId]?.teamName || '';
            bValue = teams[b.team1.teamId]?.teamName || '';
            break;
          case 'score':
            aValue = a.team1score - a.team2score; // net difference
            bValue = b.team1score - b.team2score;
            break;
          case 'team2':
            aValue = teams[a.team2.teamId]?.teamName || '';
            bValue = teams[b.team2.teamId]?.teamName || '';
            break;
          case 'map':
            aValue = a.mapName;
            bValue = b.mapName;
            break;
          default:
            return 0;
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  }, [matches, sortConfig, teams]);

  /**
   * Helper: Build link for a team. If we have a `season`, append ?season=...
   * If not, we simply link to /team/:teamId
   */
  const buildTeamLink = (teamId: string) => {
    if (season !== undefined) {
      return `/team/${teamId}?season=${season}`;
    }
    console.warn(
      'No season found for teamId:',
      teamId,
      '... returning link without a season param.'
    );
    return `/team/${teamId}`;
  };

  // If no matches, show a message
  if (sortedMatches.length === 0) {
    return (
      <div className="container text-center">
        <h1>Ingen kampe fundet</h1>
      </div>
    );
  }

  // Render the table
  return (
    <div className="text-center">
      <table
        className={`table table-striped ${isDark ? 'table-dark' : ''}`}
        style={
          isDark
            ? { border: '1px solid #111', borderCollapse: 'collapse' as 'collapse' }
            : undefined
        }
      >
        <thead>
          <tr>
            {!isMobile && !compact && (
              <th
                style={{ width: '17.5%', cursor: 'pointer' }}
                onClick={() => handleSort('date')}
              >
                Dato{" "}
                {sortConfig.key === 'date' &&
                  (sortConfig.direction === 'ascending' ? '▲' : '▼')}
              </th>
            )}
            <th
              style={{ width: '25%', cursor: 'pointer' }}
              onClick={() => handleSort('team1')}
            >
              Hold 1{" "}
              {sortConfig.key === 'team1' &&
                (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th
              style={{ width: '15%', cursor: 'pointer' }}
              onClick={() => handleSort('score')}
            >
              Score{" "}
              {sortConfig.key === 'score' &&
                (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th
              style={{ width: '25%', cursor: 'pointer' }}
              onClick={() => handleSort('team2')}
            >
              Hold 2{" "}
              {sortConfig.key === 'team2' &&
                (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            {!isMobile && (
              <th
                style={{ width: '17.5%', cursor: 'pointer' }}
                onClick={() => handleSort('map')}
              >
                Map{" "}
                {sortConfig.key === 'map' &&
                  (sortConfig.direction === 'ascending' ? '▲' : '▼')}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {sortedMatches.map((match) => (
            <tr key={match.matchid} style={{ height: '80px' }}>
              {/* Date/time (if not mobile & not compact) */}
              {!isMobile && !compact && (
                <td className="align-middle">
                  {new Date(match.match_time).toLocaleDateString('da-DK', {
                    day: 'numeric',
                    month: 'long',
                  }) +
                    ', ' +
                    new Date(match.match_time).toLocaleTimeString('da-DK', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}
                </td>
              )}

              {/* Team 1 link */}
              <td className="align-middle">
                <Link
                  to={buildTeamLink(match.team1.teamId)}
                  className={`text-decoration-none ${
                    isDark ? 'text-light' : 'text-black'
                  } d-flex flex-column align-items-center`}
                >
                  <img
                    src={teams[match.team1.teamId]?.teamLogo}
                    alt={teams[match.team1.teamId]?.teamName}
                    style={{ width: 40, height: 40, objectFit: 'contain' }}
                    loading="lazy"
                  />
                  <span className="mt-1 text-truncate" style={{ maxWidth: '100%' }}>
                    {teams[match.team1.teamId]?.teamName}
                  </span>
                </Link>
              </td>

              {/* Score */}
              <td className="align-middle" style={{ height: '100%' }}>
                <div style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>
                  <span className={match.team1won ? 'text-success' : 'text-danger'}>
                    {match.team1score}
                  </span>
                  <span className="mx-2">-</span>
                  <span className={match.team1won ? 'text-danger' : 'text-success'}>
                    {match.team2score}
                  </span>
                </div>
                {/* Link to the match details page */}
                <Link to={`/match/${match.matchid}`} className="btn btn-primary">
                  Se kamp
                </Link>
              </td>

              {/* Team 2 link */}
              <td className="align-middle">
                <Link
                  to={buildTeamLink(match.team2.teamId)}
                  className={`text-decoration-none ${
                    isDark ? 'text-light' : 'text-black'
                  } d-flex flex-column align-items-center`}
                >
                  <img
                    src={teams[match.team2.teamId]?.teamLogo}
                    alt={teams[match.team2.teamId]?.teamName}
                    style={{ width: 50, height: 50, objectFit: 'contain' }}
                    loading="lazy"
                  />
                  <span className="mt-1 text-truncate" style={{ maxWidth: '100%' }}>
                    {teams[match.team2.teamId]?.teamName}
                  </span>
                </Link>
              </td>

              {/* Map info (if not mobile) */}
              {!isMobile && (
                <td className="align-middle">
                  {match.bo3 ? 'BO3' : mapMappings.get(match.mapName) || match.mapName}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MatchTable;
