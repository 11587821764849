import { Link, useParams } from "react-router-dom";
import usePlayerData from '../../hooks/usePlayerData';
import PlayerInfo from "./PlayerInfo/PlayerInfo";
import PlayerStatsDisplay from "./PlayerStats";
import '../../styles/theme.css';
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { Team } from "../../models/Team";
import TabsComponent from "./PlayerTabs";
import PlayerMatch from "./PlayerMatch";
import SocialMediaButtons from "./SocialMediaLinks";
import Loading from "../../components/Loading";
import { fetchPlayerBySteamIdAndSeason } from "../../services/PlayerStatService";
import { getLatestSeason } from "../../services/MatchService";
import { AuthContext } from "../../context/AuthContext";
import { fetchCurrentTeam } from "../../services/AccountService";
import { admins, currentSeason } from "../../Constants";
import PlayerAchivementsDisplay from "./PlayerAchievement/PlayerAchivements";
import useHasFantasyTeam from '../../hooks/useHasFantasyTeam'; // Import the hook
import useHasPrediction from "../../hooks/useHasPrediction";

const PlayerTeams = lazy(() => import("./PlayerTeams"));

const PlayerPage = () => {
    const { steamId } = useParams();
    const [team, setTeam] = useState<Team | null>();
    const { playerData, loading: playerDataLoading } = usePlayerData(steamId);
    const [activeTab, setActiveTab] = useState('stat');
    const { user: visitor } = useContext(AuthContext);
    const isLoading = playerDataLoading;

    const hasFantasyTeam = useHasFantasyTeam(playerData?.steamID); // Use the hook to check if the player has a fantasy team
    const hasPredicted = useHasPrediction(playerData?.steamID); // Use the new hook

    const handleTabChange = (tabId: string) => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        const fetchTeam = async () => {
            if (!steamId) return;

            try {
                const season = await getLatestSeason();
                const playerStat = await fetchPlayerBySteamIdAndSeason(steamId, season);
                if (!playerStat) return;
                const team = await fetchCurrentTeam(steamId);
                setTeam(team);
            }
            catch (e) {
                setTeam(null);
            }
        };
        fetchTeam();
    }, [steamId]);

    return (
        <div className="container">
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className="row align-items-center">
                <div className="col-md-2 text-left">
                  {visitor?.steamID === steamId || admins.find((admin) => admin === Number(visitor?.steamID)) ? (
                    <Link to={`/player/${steamId}/edit`} className="btn btn-primary mt-2 mb-2">
                      Rediger
                    </Link>
                  ) : null}
                </div>
              </div>
      
              <div className="row justify-content-center">
                {hasFantasyTeam && hasPredicted ? (
                  // Both buttons present: side by side
                  <>
                    <div className="col-md-6 text-center">
                      <Link
                        to={`/fantasy/view/${steamId}/${currentSeason}`}
                        className="btn btn-primary mt-2 mb-2"
                      >
                        {playerData?.playerName} Fantasy Hold
                      </Link>
                    </div>
                    <div className="col-md-6 text-center">
                      <Link
                        to={`/standings/predict/${steamId}`}
                        className="btn btn-primary mt-2 mb-2"
                      >
                        Se Forudsigelse
                      </Link>
                    </div>
                  </>
                ) : hasFantasyTeam ? (
                  // Only Fantasy Team button: center it
                  <div className="col-md-6 text-center">
                    <Link
                      to={`/fantasy/view/${steamId}/${currentSeason}`}
                      className="btn btn-primary mt-2 mb-2"
                    >
                      {playerData?.playerName} Fantasy Hold
                    </Link>
                  </div>
                ) : hasPredicted ? (
                  // Only Prediction button: center it
                  <div className="col-md-6 text-center">
                    <Link
                      to={`/standings/predict/${steamId}`}
                      className="btn btn-primary mt-2 mb-2"
                    >
                      Se Forudsigelse
                    </Link>
                  </div>
                ) : null}
              </div>
      
              <PlayerInfo playerData={playerData} teamData={team} />
            </div>
          )}

    <hr />
    <div className="text-center">
        <SocialMediaButtons playerData={playerData} steamId={steamId} />
    </div>
    <hr />
    <TabsComponent onTabChange={handleTabChange} />
    <hr />
    {activeTab === 'stat' && (
        <Suspense fallback={<Loading />}>
            <PlayerStatsDisplay />
        </Suspense>
    )}
    {activeTab === 'team' && (
        <Suspense fallback={<Loading />}>
            <PlayerTeams />
        </Suspense>
    )}
    {activeTab === 'matches' && (
        <Suspense fallback={<Loading />}>
            <PlayerMatch />
        </Suspense>
    )}
    {activeTab === 'achievements' && (
        <Suspense fallback={<Loading />}>
            <PlayerAchivementsDisplay />
        </Suspense>
    )}
</div>

    );
}

export default PlayerPage;
