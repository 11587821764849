import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import AllDivisionPredictions from '../../components/AllDivisionPredictions';
import { currentSeason } from '../../Constants';
import { getAccountBySteamId } from "../../services/FantasyService";
import { Account } from '../../models/Account';

const UserPredictionsPage: React.FC = () => {
  const { steamId } = useParams<{ steamId: string }>();
  const [isDark] = useLocalStorage<boolean>('isDark', false);
  const [account, setAccount] = useState<Account | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAccount = async () => {
      if (!steamId) return;
      
      try {
        setLoading(true);
        const accountData = await getAccountBySteamId(steamId);
        setAccount(accountData);
        setError(null);
      } catch (err) {
        console.error('Error fetching account:', err);
        setError('Could not load user information');
      } finally {
        setLoading(false);
      }
    };

    fetchAccount();
  }, [steamId]);

  if (!steamId) {
    return <div className={`container-fluid ${isDark ? 'bg-dark text-white' : ''}`}>No user specified</div>;
  }

  if (loading) {
    return (
      <div className={`container-fluid ${isDark ? 'bg-dark text-white' : ''}`} style={{ padding: '1.5rem 10px' }}>
        <div>Loading user information...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`container-fluid ${isDark ? 'bg-dark text-white' : ''}`} style={{ padding: '1.5rem 10px' }}>
        <div className="text-danger">{error}</div>
      </div>
    );
  }

  return (
    <div className={`container-fluid ${isDark ? 'bg-dark text-white' : ''}`} style={{ padding: '1.5rem 10px' }}>
      <h1>Predictions by {account?.playerName || 'Unknown User'}</h1>
      <hr />
      <AllDivisionPredictions 
        season={currentSeason} 
        steamId={steamId}
      />
    </div>
  );
};

export default UserPredictionsPage;