import React, { useState, useEffect, useContext } from "react";
import ReadOnlyTeamSlot from "../FantasyPage/ReadOnlyTeamSlot";
import { useParams } from "react-router-dom";
import {
  getDivisionStandings,
  getDivisionsBySeason,
  getOldestSeason,
  createStandingsPrediction,
  updateStandingsPrediction,
  getStandingsPrediction,
  getTeamStanding,
} from "../../services/MatchService";
import * as FantasyService from "../../services/FantasyService";
import { currentSeason, divisionMappings } from "../../Constants";
import { Standing } from "../../models/Standing";
import { AuthContext } from "../../context/AuthContext";
import { FantasyPlayer } from "../../models/FantasyPlayer";
import AllDivisionPredictions from "../../components/AllDivisionPredictions";
import useLocalStorage from "use-local-storage";

/** Each unranked team on the left side */
interface Team {
  teamId: string;
  teamName: string;
  teamLogo: string;
  players?: FantasyPlayer[]; // Optional property for players
  avgElo?: number; // Added avgElo
}

/** Each rank row has a static rank (1..N), plus an optional occupant. */
interface RankSlot {
  rank: number;
  team?: Team;
}

/** We'll store { side, index } in dataTransfer for DnD. */
interface DragPayload {
  side: "left" | "rank";
  index: number;
}

const PredictStandingsPage: React.FC = () => {
  const [selectedTeamToDisplay, setSelectedTeamToDisplay] = useState<Team | null>(null);
  const { season: seasonParam } = useParams<{ season: string }>();
  const [selectedSeason, setSelectedSeason] = useState<number>(
    seasonParam ? Number(seasonParam) : currentSeason
  );
  const [isDark] = useLocalStorage<boolean>('isDark', false);
  const [lastSeasonStanding, setLastSeasonStanding] = useState<Standing | null>(null);
  const [dropHandled, setDropHandled] = useState(false);
  const [seasons, setSeasons] = useState<number[]>([]);
  const [divisions, setDivisions] = useState<string[]>([]);
  const [teamPlayers, setTeamPlayers] = useState<FantasyPlayer[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<string>("");
  const { user: visitor, token } = useContext(AuthContext);
  const [teamsLeft, setTeamsLeft] = useState<Team[]>([]);
  const [rankSlots, setRankSlots] = useState<RankSlot[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saveMessage, setSaveMessage] = useState<string>("");
  const [groupAvgElo, setGroupAvgElo] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);


  const handleTeamClick = (team: Team) => {
    setSelectedTeamToDisplay(team);
    if (team.players) {
      setTeamPlayers(team.players);
    }
  };

  


  // Load seasons effect
  useEffect(() => {
    console.log("useEffect: loadSeasons");
    const loadSeasons = async () => {
      setLoading(true);
      try {
        const oldest = await getOldestSeason();
        const seasonRange = Array.from(
          { length: currentSeason - oldest + 1 },
          (_, i) => oldest + i
        ).sort((a, b) => b - a);
        console.log("Loaded seasons:", seasonRange);
        setSeasons(seasonRange);
      } catch (err: any) {
        console.error("Error loading seasons:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadSeasons();
  }, []);

  // Load last season standing effect
  useEffect(() => {
    const fetchLastSeasonStanding = async () => {
      if (!selectedTeamToDisplay) return;

      try {
        const lastSeason = selectedSeason - 1;
        const teamStanding = await getTeamStanding(selectedTeamToDisplay.teamId, lastSeason);
        setLastSeasonStanding(teamStanding || null);
      } catch (err) {
        console.error("Error fetching last season standing:", err);
        setLastSeasonStanding(null);
      }
    };

    fetchLastSeasonStanding();
  }, [selectedTeamToDisplay, selectedSeason]);

  useEffect(() => {
    const cleanup = () => {
      setDropHandled(false);
      setIsDragging(false);
    };
    
    // Clean up on unmount and also attach to window blur event
    window.addEventListener('blur', cleanup);
    return () => {
      cleanup();
      window.removeEventListener('blur', cleanup);
    };
  }, []);

  const handleDragOverRow = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
  };

  // Load divisions effect
  useEffect(() => {
    console.log("useEffect: loadDivisions for season", selectedSeason);
    if (!selectedSeason) return;
    setLoading(true);
    const loadDivisions = async () => {
      try {
        const data: string[] = await getDivisionsBySeason(selectedSeason);
        console.log("Loaded divisions:", data);
        // Sort so that "ligaen" comes first
        const sortedData = data.sort((a, b) => {
          if (a === "ligaen") return -1;  // a should come before b
          if (b === "ligaen") return 1;   // b should come before a
          return 0; // maintain original order for others; or use localeCompare for alphabetical sort
        });
        setDivisions(sortedData);
      } catch (err: any) {
        console.error("Error loading divisions:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    loadDivisions();
  }, [selectedSeason]);


  // Load teams effect
  useEffect(() => {
    console.log("useEffect: loadTeamsFromStandings for division", selectedDivision);
    if (!selectedDivision) {
      setTeamsLeft([]);
      setSelectedTeamToDisplay(null);
      setRankSlots([]);
      setGroupAvgElo(0);
      return;
    }
    setLoading(true);

    const loadTeamsAndPredictions = async () => {
      try {
        // 1. Get standings data
        const standingsData = await getDivisionStandings(selectedSeason, selectedDivision);
        const firstKey = Object.keys(standingsData)[0] || "";
        const standingArr: Standing[] = standingsData[firstKey] || [];

        // 2. Get cached fantasy teams data
        const fantasyTeams = await FantasyService.getAllTeams(selectedSeason, selectedDivision);

        // 3. Create a map of teamId to players
        const teamPlayersMap = new Map();
        if (fantasyTeams) {
          fantasyTeams.forEach(team => {
            if (team.players) {
              const teamId = team.id.toString();
              if (!teamPlayersMap.has(teamId)) {
                teamPlayersMap.set(teamId, []);
              }
              team.players.forEach(player => {
                if (player.account?.faceitElo) {
                  teamPlayersMap.get(teamId).push(player);
                }
              });
            }
          });
        }

        // 4. Create teams with ELO data
        const teamsWithData = standingArr.map(standing => {
          const players = teamPlayersMap.get(standing.team.teamId) || [];
          const totalElo = players.reduce(
            (sum: number, player: { account: { faceitElo: any; }; }) => sum + (player.account?.faceitElo || 0),
            0
          );
          const avgElo = players.length ? Math.round(totalElo / players.length) : 0;

          return {
            teamId: standing.team.teamId,
            teamName: standing.team.teamName,
            teamLogo: standing.team.teamLogo,
            avgElo,
            players
          };
        });

        // 5. Calculate group average ELO
        const totalGroupElo = teamsWithData.reduce((sum, team) => sum + (team.avgElo || 0), 0);
        const groupAvg = Math.round(totalGroupElo / teamsWithData.length);
        setGroupAvgElo(groupAvg);

        // 6. Load existing predictions if user is logged in
        if (visitor?.steamID) {
          try {
            const existingPrediction = await getStandingsPrediction(
              visitor.steamID,
              selectedSeason,
              selectedDivision
            );

            if (existingPrediction?.teams && existingPrediction.teams.length > 0) {
              // Create a map of teamId to team object
              const teamMap = new Map(teamsWithData.map(team => [team.teamId, team]));

              // Sort predictions by predicted rank
              const sortedPredictions = [...existingPrediction.teams].sort((a, b) =>
                a.predictedRank - b.predictedRank
              );

              // Create rank slots with predicted teams
              const newRankSlots = teamsWithData.map((_, i) => {
                const prediction = sortedPredictions.find(p => p.predictedRank === i + 1);
                return {
                  rank: i + 1,
                  team: prediction ? teamMap.get(prediction.teamId) : undefined
                };
              });

              // Set teams left to only include unpredicted teams
              const predictedTeamIds = new Set(existingPrediction.teams.map(p => p.teamId));
              const remainingTeams = teamsWithData.filter(team => !predictedTeamIds.has(team.teamId));

              setRankSlots(newRankSlots);
              setTeamsLeft(remainingTeams);
              return; // Exit early since we've set up the predictions
            }
          } catch (err) {
            console.error("Error loading prediction:", err);
            // Continue with normal initialization if prediction loading fails
          }
        }

        // 7. If no predictions were loaded, initialize with empty rank slots
        setTeamsLeft(teamsWithData);
        setRankSlots(teamsWithData.map((_, i) => ({
          rank: i + 1,
          team: undefined
        })));

      } catch (err: any) {
        console.error("Error loading standings:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadTeamsAndPredictions();
  }, [selectedDivision, selectedSeason, visitor?.steamID]);
  const handleSave = async () => {
    if (!selectedDivision) {
      setSaveMessage("Please select a division first");
      return;
    }

    if (!visitor) {
      setSaveMessage("You must be logged in to save predictions");
      return;
    }

    if (teamsLeft.length > 0) {
      setSaveMessage("Please rank all teams before saving");
      return;
    }

    setLoading(true);
    setSaveMessage("");

    try {
      const teamPredictions = rankSlots
        .filter(slot => slot.team)
        .map(slot => ({
          teamId: slot.team!.teamId,
          predictedRank: slot.rank
        }));

      const existingPrediction = await getStandingsPrediction(
        visitor.steamID,
        selectedSeason,
        selectedDivision
      );

      if (existingPrediction) {
        await updateStandingsPrediction(
          visitor.steamID,
          selectedSeason,
          selectedDivision,
          teamPredictions
        );
        setSaveMessage("Prediction updated successfully!");
      } else {
        const predictionId = await createStandingsPrediction(
          visitor.steamID,
          selectedSeason,
          selectedDivision,
          teamPredictions
        );
        setSaveMessage(`Prediction saved with ID: ${predictionId}`);
      }
    } catch (err: any) {
      setSaveMessage(`Error saving prediction: ${err.message}`);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDragStart = (side: "left" | "rank", index: number) =>
    (e: React.DragEvent<HTMLTableRowElement>) => {
      try {
        setIsDragging(true);
        const payload: DragPayload = { side, index };
        e.dataTransfer.setData("application/json", JSON.stringify(payload));
        e.dataTransfer.effectAllowed = 'move';
        e.currentTarget.classList.add('grabbing');  // Add this line
      } catch (error) {
        console.error("Drag start error:", error);
        setIsDragging(false);
        setDropHandled(false);
      }
    };

    const handleDropOnRankRow = (targetIndex: number) =>
      (e: React.DragEvent<HTMLTableRowElement>) => {
        e.preventDefault();
        e.stopPropagation();
    
        if (!isDragging || dropHandled) {
          console.log("Drop ignored - not dragging or already handled");
          return;
        }
    
        try {
          setDropHandled(true);
          const raw = e.dataTransfer.getData("application/json");
          
          if (!raw) {
            throw new Error("No drag payload found");
          }
    
          const { side, index } = JSON.parse(raw) as DragPayload;
    
          if (side === "left") {
            const fromTeam = teamsLeft[index];
            if (!fromTeam) {
              throw new Error("Invalid source team");
            }
    
            setTeamsLeft(prev => {
              const newArr = [...prev.slice(0, index), ...prev.slice(index + 1)];
              return newArr;
            });
    
            setRankSlots(prev => {
              const copy = [...prev];
              const occupant = copy[targetIndex].team;
              if (occupant) {
                setTeamsLeft(prevLeft => [...prevLeft, occupant]);
              }
              copy[targetIndex] = {
                rank: copy[targetIndex].rank,
                team: fromTeam
              };
              return copy;
            });
          } else if (side === "rank") {
            setRankSlots(prev => {
              const copy = [...prev];
              // Remove the check for both slots having teams
              // We just need the source slot to have a team
              if (!copy[index].team) {
                throw new Error("Source slot is empty");
              }
    
              // Store the teams
              const sourceTeam = copy[index].team;
              const targetTeam = copy[targetIndex].team;
    
              // Update the slots
              copy[targetIndex] = {
                ...copy[targetIndex],
                team: sourceTeam
              };
              
              copy[index] = {
                ...copy[index],
                team: targetTeam  // This might be undefined, which is fine
              };
    
              return copy;
            });
          }
        } catch (error) {
          console.error("Drop error:", error);
        } finally {
          setIsDragging(false);
          setDropHandled(false);
        }
      };
    
      const handleDragEnd = (e: React.DragEvent<HTMLTableRowElement>) => {
        setIsDragging(false);
        setDropHandled(false);
        e.currentTarget.classList.remove('grabbing');  // Add this line
      };

  const getStandingColor = (division: string, index: number, isDark: boolean) => {
    // Default to the current row's background color instead of white
    let color = isDark
      ? index % 2 === 0 ? '#2C3034' : '#212529'
      : index % 2 === 0 ? '#F2F2F2' : '#FFFFFF';
  
    if (division === "ligaen") {
      if (index < 4) color = "green";
      else if (index === 7) color = "orange";
      else if (index === 8) color = "red";
    }
    else if (String(division).startsWith("2div") || division.endsWith("div")) {
      if (index === 0) color = "green";
      else if (index === 1) color = "yellow";
      else if (index === 6 || index === 7) color = "orange";
      else if (index === 8 || index === 9 || index === 10) color = "red";
    }
    else if (String(division).startsWith("3div")) {
      if (index === 0) color = "green";
      else if (index >= 1 && index <= 2) color = "yellow";
      else if (index >= 5 && index <= 7) color = "orange";
      else if (index >= 8 && index <= 9) color = "red";
    }
    else if (String(division).startsWith("4div")) {
      if (index === 0 || index === 1) color = "green";
      else if (index >= 2 && index <= 4) color = "yellow";
    }
    return color;
  };

  return (
    <div className={`container-fluid ${isDark ? 'bg-dark text-white' : ''}`} style={{ padding: '1.5rem 10px' }}>
      <style>{`
        .grabbing {
          cursor: grabbing !important;
          user-select: none;
        }
      `}</style>
      <h1>Predict Standings</h1>
      <hr />

      {/* Season, Division dropdowns & Save button */}
      <div className="row mb-3">
        <div className="col-md-3">
          <label>Vælg sæson:</label>
          <div className="dropdown mb-2">
            <button
              className="btn btn-primary dropdown-toggle w-100"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Sæson {selectedSeason}
            </button>
            <ul className="dropdown-menu w-100">
              {seasons.map((s) => (
                <li key={s}>
                  <button
                    className="dropdown-item text-center"
                    onClick={() => setSelectedSeason(s)}
                  >
                    Sæson {s}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="col-md-3">
          <label>Vælg division:</label>
          <div className="dropdown mb-2">
            <button
              className="btn btn-primary dropdown-toggle w-100"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selectedDivision
                ? divisionMappings.get(selectedDivision) ?? selectedDivision
                : "Alle"}
            </button>
            <ul className="dropdown-menu w-100">
              <li>
                <button
                  className="dropdown-item text-center"
                  onClick={() => {
                    setSelectedDivision("");
                    setSelectedTeamToDisplay(null);
                  }}
                >
                  Alle
                </button>
              </li>
              {divisions.map((d) => (
                <li key={d}>
                  <button
                    className="dropdown-item text-center"
                    onClick={() => {
                      setSelectedDivision(d);
                      setSelectedTeamToDisplay(null);
                    }}
                  >
                    {divisionMappings.get(d) ?? d}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Save Button */}
        <div className="col-md-3">
          <label>&nbsp;</label>
          <div className="mb-2">
            <button
              className="btn btn-primary w-100"
              onClick={handleSave}
              disabled={loading || teamsLeft.length > 0}
            >
              {loading ? "Saving..." : "Save Prediction"}
            </button>

            {saveMessage && (
              <div
                className={`alert ${saveMessage.includes("Error") ? "alert-danger" : "alert-success"
                  } mt-2`}
              >
                {saveMessage}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* If no division selected, show all predictions */}
      {!selectedDivision && (
        <div className="row">
          <div className="col-12">
            {visitor?.steamID ? (
              <AllDivisionPredictions
                season={selectedSeason}
                steamId={visitor.steamID}
              />
            ) : (
              <p>Log ind for at se dine predictions.</p>
            )}
          </div>
        </div>
      )}

      {selectedDivision && (
        <div className="row">
          <div className="col-md-9">
            <div className="row" style={{ margin: '0 -10px' }}>
              {/* LEFT TABLE -> Unranked teams */}
              <div className="col-md-6" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <h5>
                  Hold i gruppen {groupAvgElo > 0 && `(AVG ELO ${groupAvgElo})`}
                </h5>

                <div
                  className={`card ${isDark ? 'text-white' : ''}`}
                  style={{
                    border: isDark ? '1px solid #000' : 'none',
                    backgroundColor: isDark ? '#2C3034' : '#fff',
                  }}
                >
                  <div className="card-body">
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr style={{
                          backgroundColor: isDark ? '#2C3034' : '#f8f9fa',
                          color: isDark ? '#fff' : '#000',
                          borderBottom: isDark ? '1px solid #555' : '1px solid #ccc',
                          height: '45px'
                        }}>
                          <th style={{
                            backgroundColor: '#e25623',
                            color: '#fff',
                            width: '70%',
                            padding: '0.5rem 0.75rem',
                          }}>Hold</th>
                          <th style={{
                            backgroundColor: '#e25623',
                            color: '#fff',
                            width: '30%',
                            padding: '0.5rem 0.75rem',
                            textAlign: 'center'
                          }}>Logo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {teamsLeft.map((team, idx) => (
                          <tr
                            key={team.teamId}
                            draggable
                            style={{
                              backgroundColor: isDark
                                ? idx % 2 === 0 ? '#2C3034' : '#212529'
                                : idx % 2 === 0 ? '#F2F2F2' : '#FFFFFF',
                              color: isDark ? '#fff' : '#000',
                              borderBottom: isDark ? '1px solid #555' : '1px solid #ccc',
                              cursor: 'grab',
                              height: '45px',
                              userSelect: 'none',
                            }}
                            onDragStart={handleDragStart("left", idx)}
                            onDragEnd={handleDragEnd}
                            onClick={() => handleTeamClick(team)}
                          >
                            <td style={{ padding: '0.5rem 0.75rem' }}>{team.teamName}</td>
                            <td style={{ padding: '0.5rem 0.75rem', textAlign: 'center' }}>
                              <img
                                src={team.teamLogo}
                                alt={team.teamName}
                                style={{
                                  width: 25,
                                  height: 25,
                                  objectFit: 'contain',
                                  verticalAlign: 'middle'
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                        {teamsLeft.length === 0 && (
                          <tr style={{
                            backgroundColor: isDark
                              ? '#2C3034'
                              : '#F2F2F2',
                            color: isDark ? '#fff' : '#000',
                            borderBottom: isDark ? '1px solid #555' : '1px solid #ccc',
                            height: '45px'
                          }}>
                            <td colSpan={2} style={{ padding: '0.5rem 0.75rem', textAlign: 'center' }}>
                              Ingen ledige hold
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {/* RIGHT TABLE -> Fixed rank slots */}
              <div className="col-md-6" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <h5>Flyt hold hertil</h5>
                <div
                  className={`card ${isDark ? 'text-white' : ''}`}
                  style={{
                    border: isDark ? '1px solid #000' : 'none',
                    backgroundColor: isDark ? '#2C3034' : '#fff',
                  }}
                >
                  <div className="card-body">
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr style={{
                          backgroundColor: isDark ? '#2C3034' : '#f8f9fa',
                          color: isDark ? '#fff' : '#000',
                          borderBottom: isDark ? '1px solid #555' : '1px solid #ccc',
                          height: '45px'
                        }}>
                          <th style={{
                            backgroundColor: '#e25623',
                            color: '#fff',
                            width: '20%',
                            padding: '0.5rem 0.75rem',
                          }}>Rank</th>
                          <th style={{
                            backgroundColor: '#e25623',
                            color: '#fff',
                            width: '50%',
                            padding: '0.5rem 0.75rem',
                          }}>Hold</th>
                          <th style={{
                            backgroundColor: '#e25623',
                            color: '#fff',
                            width: '30%',
                            padding: '0.5rem 0.75rem',
                            textAlign: 'center'
                          }}>Logo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rankSlots.map((slot, slotIndex) => (
                          <tr
                            key={slot.rank}
                            draggable={!!slot.team}
                            style={{
                              backgroundColor: isDark
                                ? slotIndex % 2 === 0 ? '#2C3034' : '#212529'
                                : slotIndex % 2 === 0 ? '#F2F2F2' : '#FFFFFF',
                              color: isDark ? '#fff' : '#000',
                              borderBottom: isDark ? '1px solid #555' : '1px solid #ccc',
                              cursor: slot.team ? 'grab' : 'default',
                              height: '45px',
                              userSelect: 'none',
                            }}
                            onDragStart={handleDragStart("rank", slotIndex)}
                            onDragEnd={handleDragEnd}
                            onDragOver={handleDragOverRow}
                            onDrop={handleDropOnRankRow(slotIndex)}
                            onClick={() => slot.team && handleTeamClick(slot.team)}
                          >
                            <td style={{
                              padding: '0.5rem 0.75rem',
                              backgroundColor: getStandingColor(selectedDivision, slotIndex, isDark)
                            }}>{slot.rank}</td>
                            {slot.team ? (
                              <>
                                <td style={{ padding: '0.5rem 0.75rem' }}>{slot.team.teamName}</td>
                                <td style={{ padding: '0.5rem 0.75rem', textAlign: 'center' }}>
                                  <img
                                    src={slot.team.teamLogo}
                                    alt={slot.team.teamName}
                                    style={{
                                      width: 25,
                                      height: 25,
                                      objectFit: 'contain',
                                      verticalAlign: 'middle'
                                    }}
                                  />
                                </td>
                              </>
                            ) : (
                              <td
                                colSpan={2}
                                style={{
                                  padding: '0.5rem 0.75rem',
                                  textAlign: 'center',
                                  fontStyle: "italic"
                                }}
                              >
                                Drop a team here
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right panel */}
          <div className="col-md-3">
            {selectedTeamToDisplay ? (
              <div style={{ textAlign: 'center', position: 'sticky', top: '20px' }}>
                <h3>{selectedTeamToDisplay.teamName}</h3>
                <img
                  src={selectedTeamToDisplay.teamLogo}
                  alt={selectedTeamToDisplay.teamName}
                  style={{
                    maxWidth: '200px',
                    height: 'auto',
                    maxHeight: '200px',
                    objectFit: 'contain'
                  }}
                />
                {lastSeasonStanding && (
                  <div style={{ marginTop: '10px' }}>
                    <p>
                      Ranked {lastSeasonStanding.rank} in{" "}
                      {divisionMappings.get(lastSeasonStanding.team.division) || lastSeasonStanding.team.division}
                    </p>
                    <p>AVG ELO {selectedTeamToDisplay.avgElo}</p>
                  </div>
                )}
                {!lastSeasonStanding && (
                  <div style={{ marginTop: '10px' }}>
                    <p>AVG ELO {selectedTeamToDisplay.avgElo}</p>
                  </div>
                )}
              </div>
            ) : (
              <div style={{ textAlign: 'center', position: 'sticky', top: '20px' }}>
                <p>Click on a team to see details</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Team slot details */}
      {selectedTeamToDisplay && teamPlayers.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <ReadOnlyTeamSlot
            team={{
              ...selectedTeamToDisplay,
              players: teamPlayers,
              avgElo: selectedTeamToDisplay.avgElo
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PredictStandingsPage;