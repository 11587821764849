import { Suspense, useCallback, useContext, useEffect, useState } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom"; // <-- import useSearchParams
import { fetchSeasonsPlayed, fetchTeamStanding } from "../../services/TeamService";
import PlayerImage from "../../components/PlayerImage";
import { admins, divisionMappings } from "../../Constants";
import useTeamData from "../../hooks/useTeamData";
import '../../styles/theme.css';
import SeasonScroll from "../../components/SeasonScroll/SeasonScroll";
import TeamTabs from "./TeamTabs";
import TeamPlayers from "./TeamPlayers";
import TeamMatches from "./TeamMatches";
import { Standing } from "../../models/Standing";
import { Account } from "../../models/Account";
import { fetchAccount } from "../../services/AccountService";
import Loading from "../../components/Loading";
import { AuthContext } from "../../context/AuthContext";
import TeamMapStats from "./TeamMapStats";

const TeamPage = () => {
  const { teamId } = useParams<{ teamId: string }>();

  // 1) Grab search params from the URL
  const [searchParams] = useSearchParams();

  // 2) We'll store the currently viewed season in state
  const [season, setSeason] = useState<number>(0);
  const [seasons, setSeasons] = useState<number[]>();
  const [owner, setOwner] = useState<Account | null>(null);
  const [coach, setCoach] = useState<Account | null>(null);
  const [standing, setStanding] = useState<Standing>();
  const { teamData, players, loading } = useTeamData(teamId, season);
  const [activeTab, setActiveTab] = useState<string>();
  const { user: visitor } = useContext(AuthContext);
  const isLoading = loading || !teamData || !seasons;

  // Fetch the team standing for the current season
  const getTeamStanding = useCallback(async () => {
    if (!teamId || season === 0) return;
    try {
      const teamStanding = await fetchTeamStanding(teamId, season);
      setStanding(teamStanding);
    } catch (error) {
      console.error(error);
    }
  }, [teamId, season]);

  // Fetch all seasons the team has played
  const getSeasonsPlayed = useCallback(async () => {
    if (!teamId) return;
    try {
      const seasons = await fetchSeasonsPlayed(teamId);
      setSeasons(seasons);
      // Don't set the season here; we'll do that after seasons are loaded (see useEffect below).
    } catch (error) {
      console.error(error);
    }
  }, [teamId]);

  // Once the team data is available, fetch the owner & coach info
  useEffect(() => {
    const getOwnerAndCoach = async () => {
      if (!teamData) {
        setOwner(null);
        setCoach(null);
        return;
      }
      try {
        const owner = await fetchAccount(teamData.ownerId);
        if (!owner) throw new Error('No owner found');
        setOwner(owner);

        if (teamData.coachID) {
          const coach = await fetchAccount(teamData.coachID);
          if (!coach) throw new Error('No coach found');
          setCoach(coach);
        } else {
          setCoach(null);
        }
      } catch (error) {
        console.error(error);
        setOwner(null);
        setCoach(null);
      }
    };
    getOwnerAndCoach();
  }, [teamData]);

  // Get seasons for this team on initial mount
  useEffect(() => {
    getSeasonsPlayed();
  }, [getSeasonsPlayed]);

  // Decide which season to display (the query param or fallback) once 'seasons' is loaded
  useEffect(() => {
    if (!seasons) return; // not loaded yet

    const querySeason = searchParams.get('season');
    const parsedQuerySeason = querySeason ? parseInt(querySeason, 10) : NaN;

    if (!isNaN(parsedQuerySeason) && seasons.includes(parsedQuerySeason)) {
      setSeason(parsedQuerySeason);
    } else {
      // fallback to the highest season the team has played
      setSeason(Math.max(...seasons));
    }
  }, [seasons, searchParams]);

  // Whenever 'season' changes, fetch the standing for that season
  useEffect(() => {
    getTeamStanding();
  }, [getTeamStanding, season]);

  // Default active tab
  useEffect(() => {
    if (activeTab === undefined) {
      setActiveTab('players');
    }
  }, [activeTab]);

  // Called when the user chooses a different season in the SeasonScroll
  const handleSeasonChange = useCallback(
    (selectedSeason: number) => {
      setSeason(selectedSeason);
      setActiveTab('players');
    },
    [setSeason]
  );

  // Called when switching tabs in TeamTabs
  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };

  // Utility function
  const calculateAverageRating = (): string => {
    if (!players || players.length === 0) return "0.00";
    const totalRating = players.reduce((sum, player) => sum + player.hltv2Rating, 0);
    return (totalRating / players.length).toFixed(2);
  };

  if (isLoading) {
    return (
      <div className="container text-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="container">
      {/* If this user is an admin or the owner, show edit button linking to /team/:teamId/edit?season=... */}
      <div className="text-end">
        {
          (visitor && (visitor.steamID === owner?.steamID || admins.includes(Number(visitor.steamID)))) && (
            <Link to={`/team/${teamId}/edit?season=${season}`} className="btn btn-primary">
              Rediger hold
            </Link>
          )
        }
      </div>

      {/* Scrollable season selector */}
      <SeasonScroll
        seasons={seasons}
        selectedSeason={season}
        onSeasonChange={handleSeasonChange}
      />

      <hr />

      {/* Team header info */}
      <div className="text-center mt-5 mb-2">
        <div className="row">
          <div className="col-7">
            <h1>{teamData.teamName}</h1>
            <img src={teamData.teamLogo} alt={teamData.teamName} style={{ width: 200 }} />
            <Link to={`/standing/${season}/${teamData.division}`} className="text-decoration-none">
              <h4>{divisionMappings.get(teamData.division)}</h4>
            </Link>
            {standing && (
              <div>
                <Link to={`/standing/${season}/${teamData.division}`} className="text-decoration-none">
                  <h5>Placering: {standing.rank}</h5>
                </Link>
              </div>
            )}
          </div>

          {/* Owner & Coach */}
          <div className="col mt-2">
            <h2>Holdejer {coach && "og Coach"}</h2>

            {/* Responsive row for owner and coach */}
            <div className="row row-cols-1 row-cols-sm-2 g-3 justify-content-center text-center">
              {/* Owner Card */}
              {owner ? (
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/player/${owner.steamID}`} className="text-decoration-none text-black">
                        <PlayerImage id={owner.steamID} width={100} />
                        <h5 className="card-title text-center">{owner.playerName}</h5>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col text-center">
                  <p>
                    Der er ingen holdejer på holdet.
                    Skriv til{" "}
                    <a
                      href="https://x.com/realxGuys"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Casper
                    </a>{" "}
                    eller{" "}
                    <a
                      href="https://twitter.com/RealSoerensen"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Soerensen
                    </a>{" "}
                    fra holdets twitter konto for at tilføje en holdejer.
                  </p>
                </div>
              )}

              {/* Coach Card (only renders if a coach exists) */}
              {coach && (
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/player/${coach.steamID}`} className="text-decoration-none text-black">
                        <PlayerImage id={coach.steamID} width={100} />
                        <h5 className="card-title text-center">{coach.playerName}</h5>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Twitter and Website links */}
            {teamData.twitter && (
              <div className="mt-2">
                <h5>Twitter</h5>
                <a
                  href={`https://twitter.com/${teamData.twitter}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-info"
                >
                  @{teamData.twitter}
                </a>
              </div>
            )}
            {teamData.website && (
              <div className="mt-2">
                <h5>Hjemmeside</h5>
                <a
                  href={
                    teamData.website.startsWith("http")
                      ? teamData.website
                      : `https://${teamData.website}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-info"
                >
                  {teamData.website}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <hr />

      {/* Tabs for players, matches, veto, etc. */}
      <TeamTabs onTabChange={handleTabChange} />
      <hr />

      {activeTab === "players" && (
        <Suspense fallback={<div>Loading...</div>}>
          <TeamPlayers players={players} averageRating={calculateAverageRating()} />
        </Suspense>
      )}
      {activeTab === "matches" && (
        <Suspense fallback={<div>Loading...</div>}>
          <TeamMatches season={season} />
        </Suspense>
      )}
      {activeTab === "veto" && (
        <Suspense fallback={<div>Loading...</div>}>
          <TeamMapStats season={season} />
        </Suspense>
      )}
    </div>
  );
};

export default TeamPage;
