import React from "react";
import { FantasyPlayer } from "../../models/FantasyPlayer";
import ReadOnlyPlayerSlot from "./ReadOnlyPlayerSlotProps";

interface CustomTeam {
  teamId: string;
  teamName: string;
  teamLogo: string;
  players?: FantasyPlayer[];
  avgElo?: number; // Added avgElo
}

interface ReadOnlyTeamSlotProps {
  team: CustomTeam;
}

const ReadOnlyTeamSlot: React.FC<ReadOnlyTeamSlotProps> = ({ team }) => {
  return (
    <div className="card mb-3">
      <div className="card-header d-flex align-items-center">
        <img
          src={team.teamLogo}
          alt="Logo"
          style={{ width: "5%" }}
          loading="lazy"
        />
        <span className="ms-2" style={{ flex: 1 }}>{team.teamName}</span>
        <span style={{ flex: 1 }}></span>
      </div>
      <div className="card-body">
        <div className="row">
          {team.players && team.players.length > 0 ? (
            team.players.map((player: FantasyPlayer, index: number) => (
              <div className="col-12" style={{ flex: '0 0 20%', maxWidth: '20%', marginBottom: '20px' }} key={index}>
                <ReadOnlyPlayerSlot player={player} />
              </div>
            ))
          ) : (
            <p>No players available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyTeamSlot;
