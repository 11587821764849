import { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { Standing } from "../../models/Standing";
import {
  getDivisionStandings,
  getDivisionsBySeason,
  getOldestSeason,
} from "../../services/MatchService";
import { currentSeason, divisionMappings } from "../../Constants";
import Loading from "../../components/Loading";

interface DivisionStandings {
  [division: string]: Standing[];
}

const StandingPage = () => {
  // Read :season and :division from the URL (if provided)
  const { season, division } = useParams<{ season: string; division: string }>();

  // For storing all the data we need
  const [standings, setStandings] = useState<DivisionStandings>({});
  const [selectedSeason, setCurrentSeason] = useState<number>(
    season ? Number(season) : currentSeason
  );
  const [seasons, setSeasons] = useState<number[]>([]);
  const [divisions, setDivisions] = useState<
    { division: string; formattedDivision: string }[]
  >([]);
  const [currentDivision, setCurrentDivision] = useState<string>(division || "");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  /**
   * Load all possible seasons (from oldest to latest).
   * If no season param is in the URL, default to the latest.
   */
  useEffect(() => {
    const loadSeasons = async () => {
      setLoading(true);
      try {
        const oldest = await getOldestSeason();
        const seasonRange = Array.from(
          { length: currentSeason - oldest + 1 },
          (_, i) => oldest + i
        ).sort((a, b) => b - a); // descending
        setSeasons(seasonRange);

        if (!season) {
          // If the user didn’t provide a season in URL, default to 'latest'
          setCurrentSeason(currentSeason);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
      } finally {
        setLoading(false);
      }
    };
    loadSeasons();
  }, [season]);

  /**
   * Utility to create a division object that contains both the "raw" division
   * (e.g. "2divA") and a "formatted" version (e.g. "2. Division A").
   */
  const createDivision = useCallback(
    (divisionPrefix: string, divisionName: string) => {
      const isLigaen = divisionName === "Ligaen";
      // "Ligaen" is left as-is, otherwise we split it up
      const formattedDivision = isLigaen
        ? divisionName
        : divisionName.split(" ", 2).join(" ");
      return {
        division: divisionPrefix,
        formattedDivision,
      };
    },
    []
  );

  /**
   * Fetch the standings for the current season + current division (if any).
   */
  useEffect(() => {
    const fetchStandings = async () => {
      setLoading(true);
      try {
        console.log('Fetching standings for season:', selectedSeason, 'division:', currentDivision);
        const standingsData = await getDivisionStandings(selectedSeason, currentDivision);
        setStandings(standingsData as DivisionStandings);
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
      } finally {
        setLoading(false);
      }
    };
  
    fetchStandings();
  }, [currentDivision, selectedSeason]);
  

  /**
   * Load all divisions for the selected season
   */
  useEffect(() => {
    const loadDivisions = async () => {
      setLoading(true);
      try {
        const divisions = await getDivisionsBySeason(selectedSeason);
        const divisionMap = new Map<string, { division: string; formattedDivision: string }>();
        divisions.forEach((divisionValue) => {
          const formattedDivision = divisionMappings.get(divisionValue) || divisionValue;

          if (divisionValue === "ligaen") {
            divisionMap.set(
              divisionValue,
              createDivision(divisionValue, formattedDivision)
            );
          }

          const divisionAndGroup = divisionValue.match(/^(\d+div)([A-Z])?$/);
          if (divisionAndGroup) {
            const [divisionPrefix] = divisionAndGroup.slice(1);
            divisionMap.set(
              divisionPrefix,
              createDivision(divisionPrefix, formattedDivision)
            );
          }
        });

        const divisionArray = Array.from(divisionMap.values());
        // Sort so that 'ligaen' appears first, then "2div", "3div", etc.
        divisionArray.sort((a, b) => {
          if (a.division === "ligaen") return -1;
          if (b.division === "ligaen") return 1;
          return a.division.localeCompare(b.division);
        });
        setDivisions(divisionArray);
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
      } finally {
        setLoading(false);
      }
    };
    loadDivisions();
  }, [createDivision, selectedSeason]);

  /**
   * Decide the color for each row in the standings table (promotion/relegation).
   */
  const getStandingColor = (divisionKey: string, index: number) => {
    // You have advanced logic for season = 24. Left as-is:
    if (selectedSeason === 24) {
      let color = "white";
      if (divisionKey === "ligaen") {
        if (index < 4) color = "green";
        else if (index === 6) color = "orange";
        else if (index === 7) color = "red";
      } else if (divisionKey.endsWith("div")) {
        if (index === 0) color = "green";
        else if (index === 1) color = "yellow";
        else if (index === 6 || index === 7) color = "orange";
        else if (index === 8 || index === 9) color = "red";
      } else if (
        String(divisionKey).startsWith("2div") ||
        String(divisionKey).startsWith("3div") ||
        String(divisionKey).startsWith("4div")
      ) {
        if (index === 0) color = "green";
        else if (index >= 1 && index <= 2) color = "yellow";
        else if (index >= 5 && index <= 7) color = "orange";
        else if (index >= 8 && index <= 9) color = "red";
      } else if (String(divisionKey).startsWith("5div")) {
        if (index === 0) color = "green";
        else if (index >= 1 && index <= 2) color = "yellow";
      }
      return color;
    }

    if (selectedSeason === 28) {
      let color = "white";
      if (divisionKey === "ligaen") {
        if (index < 4) color = "green";
        else if (index === 7) color = "orange";
        else if (index === 8) color = "red";
      } else if (
        String(divisionKey).startsWith("2div") ||
        divisionKey.endsWith("div")
      ) {
        if (index === 0) color = "green";
        else if (index === 1) color = "yellow";
        else if (index === 6 || index === 7) color = "orange";
        else if (index === 8 || index === 9 || index === 10) color = "red";
      } else if (String(divisionKey).startsWith("3div")) {
        if (index === 0) color = "green";
        else if (index >= 1 && index <= 2) color = "yellow";
        else if (index >= 5 && index <= 7) color = "orange";
        else if (index >= 8 && index <= 9) color = "red";
      } else if (String(divisionKey).startsWith("4div")) {
        if (index === 0 || index === 1) {
          color = "green";
        } else if (index >= 2 && index <= 4) {
          color = "yellow";
        }
      }
      return color;
    }

    // Other seasons
    let color = "white";
    if (divisionKey === "ligaen") {
      if (index < 4) color = "green";
      else if (index === 6) color = "orange";
      else if (index === 7) color = "red";
    } else if (
      String(divisionKey).startsWith("2div") ||
      divisionKey.endsWith("div")
    ) {
      if (index === 0) color = "green";
      else if (index === 1) color = "yellow";
      else if (index === 6 || index === 7) color = "orange";
      else if (index === 8 || index === 9) color = "red";
    } else if (String(divisionKey).startsWith("3div")) {
      if (index === 0) color = "green";
      else if (index >= 1 && index <= 2) color = "yellow";
      else if (index >= 5 && index <= 7) color = "orange";
      else if (index >= 8 && index <= 9) color = "red";
    } else if (String(divisionKey).startsWith("4div")) {
      if (index === 0 || index === 1) {
        color = "green";
      } else if (index >= 2 && index <= 4) {
        color = "yellow";
      }
    }
    return color;
  };

  /**
   * Renders the standings table for each division
   */
  const renderStandings = () => {
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return (
        <div className="text-center">
          <h1>Der skete en fejl</h1>
          <p>{error}</p>
        </div>
      );
    }

    // Render each division’s table
    return (
      <div className="row">
        {Object.entries(standings).map(([divisionKey, divisionStandings]) => (
          <div key={divisionKey} className="col-12 col-sm-12 col-md-6">
            {divisionStandings && (
              <>
                <h2 className="text-center">
                  {divisionMappings.get(divisionKey)}
                </h2>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="ranking">#</th>
                      <th>Hold</th>
                      <th>Kampe - (W/L)</th>
                      <th className="points">Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {divisionStandings.map((standing, index) => {
                      const color = getStandingColor(divisionKey, index);
                      // Link to /team/:teamId?season=selectedSeason
                      const teamLink = `/team/${standing.team.teamId}?season=${selectedSeason}`;

                      return (
                        <tr key={standing.team.teamId}>
                          <td style={{ backgroundColor: color }}>
                            {standing.rank}
                          </td>
                          <td style={{ backgroundColor: color }}>
                            <Link
                              to={teamLink}
                              className="text-decoration-none text-black"
                            >
                              <img
                                src={standing.team.teamLogo}
                                alt={standing.team.teamName}
                                style={{ width: 25 }}
                              />
                              {standing.team.teamName}
                            </Link>
                          </td>
                          <td style={{ backgroundColor: color }}>
                            {standing.wins}/{standing.gamesPlayed - standing.wins}
                          </td>
                          <td
                            className="text-end"
                            style={{ backgroundColor: color }}
                          >
                            {standing.points}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="text-center">
        <h1>Standings</h1>
        <p>Her kan du se stillingen i de forskellige divisioner</p>
      </div>
      <hr />

      {/* Season dropdown */}
      <div className="row justify-content-center text-center">
        <div className="col-12 col-md-4">
          Vælg sæson
          <div className="dropdown mb-2">
            <button
              className="btn btn-primary dropdown-toggle w-100"
              type="button"
              id="seasonDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Sæson {selectedSeason}
            </button>
            <ul
              className="dropdown-menu text-center w-100"
              aria-labelledby="seasonDropdown"
            >
              {seasons.map((s) => (
                <li key={s}>
                  <button
                    className="dropdown-item"
                    onClick={() => setCurrentSeason(s)}
                  >
                    Sæson {s}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Division dropdown */}
      <div className="row justify-content-center text-center">
        <div className="col-12 col-md-4">
          Vælg division
          <div className="dropdown mb-2">
            <button
              className="btn btn-primary dropdown-toggle w-100"
              type="button"
              id="divisionDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {currentDivision
                ? divisions.find((d) => d.division === currentDivision)
                    ?.formattedDivision
                : "Alle"}
            </button>
            <ul
              className="dropdown-menu text-center w-100"
              aria-labelledby="divisionDropdown"
            >
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => setCurrentDivision("")}
                >
                  Alle
                </button>
              </li>
              {divisions.map((d) => (
                <li key={d.division}>
                  <button
                    className="dropdown-item"
                    onClick={() => setCurrentDivision(d.division)}
                  >
                    {d.formattedDivision}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {renderStandings()}
    </div>
  );
};

export default StandingPage;
