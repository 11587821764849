import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getUpcomingMatchById } from '../../services/MatchService';
import { getPlayersByTeamId } from '../../services/PlayerService';
import TeamLineupPre from '../../components/TeamLineupPre';
import Loading from '../../components/Loading';
import useLocalStorage from 'use-local-storage';
import { divisionMappings, currentSeason } from '../../Constants';
//import { PlayerMatch } from '../../models/PlayerMatch';
import { UpcomingMatch } from '../../models/UpcomingMatch';
import { PlayerStats } from '../../models/PlayerStats';

const PreMatchPage = () => {
    const { matchId } = useParams<{ matchId: string }>();
    const [isLoading, setIsLoading] = useState(true);
    const [upcomingMatch, setUpcomingMatch] = useState<UpcomingMatch | null>(null);
    const [team1Players, setTeam1Players] = useState<PlayerStats[]>([]);
    const [team2Players, setTeam2Players] = useState<PlayerStats[]>([]);
    const [isDark] = useLocalStorage<boolean>('isDark', false);

    const fetchMatchData = useCallback(async () => {
        try {
            if (!matchId) throw new Error('No matchId provided');
            const matchData = await getUpcomingMatchById(matchId);
            console.log('Fetched match data:', matchData); // Debugging
            if (!matchData) throw new Error('No match data found');
            setUpcomingMatch(matchData);
        } catch (error) {
            console.error('Error fetching match data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [matchId]);

    const fetchTeamPlayers = useCallback(async (teamId: string, setPlayers: React.Dispatch<React.SetStateAction<PlayerStats[]>>) => {
        try {
            const players = await getPlayersByTeamId(teamId, currentSeason);
            console.log(`Fetched players for team ${teamId}:`, players); // Debugging
            setPlayers(players);
        } catch (error) {
            console.error(`Error fetching players for team ${teamId}:`, error);
        }
    }, []);

    useEffect(() => {
        fetchMatchData();
    }, [fetchMatchData]);

    useEffect(() => {
        if (upcomingMatch) {
            console.log('Upcoming match data:', upcomingMatch); // Debugging
            fetchTeamPlayers(upcomingMatch.team1.teamId, setTeam1Players);
            fetchTeamPlayers(upcomingMatch.team2.teamId, setTeam2Players);
        }
    }, [upcomingMatch, fetchTeamPlayers]);

    useEffect(() => {
        console.log('Team 1 players:', team1Players); // Debugging
        console.log('Team 2 players:', team2Players); // Debugging
    }, [team1Players, team2Players]);

    if (isLoading) {
        return (
            <div className={`container text-center ${isDark ? 'text-light bg-dark' : ''}`}>
                <Loading />
            </div>
        );
    }

    if (!upcomingMatch) {
        return <div>No match data available.</div>;
    }

    return (
        <div className={`prematch-page ${isDark ? 'bg-dark text-light' : ''}`}>
            <div className="container text-center">
                <div
                    className="row align-items-center"
                    style={{
                        backgroundColor: '#e25623',
                        borderRadius: '10px',
                        padding: '15px 0',
                        marginBottom: '20px',
                    }}
                >
                    <div className="col-4 d-flex align-items-center justify-content-center">
                        <Link
                            to={`/team/${upcomingMatch.team1.teamId}`}
                            className="text-decoration-none d-flex align-items-center text-light"
                        >
                            <img
                                src={upcomingMatch.team1.teamLogo}
                                alt={`${upcomingMatch.team1.teamName} Logo`}
                                style={{ width: 80, marginRight: 10 }}
                            />
                            <h4 className="m-0">{upcomingMatch.team1.teamName}</h4>
                        </Link>
                    </div>
                    <div className="col-4 text-center text-light">
                        <div>
                        {`${divisionMappings.get(upcomingMatch.division)} - Sæson ${currentSeason} - ${new Date(upcomingMatch.match_time).toLocaleDateString('da-DK').replaceAll('.', '/')}`}
                        </div>
                        <h1 className="my-2">
                            <span className="text-white">vs</span>
                        </h1>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-center">
                        <Link
                            to={`/team/${upcomingMatch.team2.teamId}`}
                            className="text-decoration-none d-flex align-items-center text-light"
                        >
                            <h4 className="m-0 me-2">{upcomingMatch.team2.teamName}</h4>
                            <img
                                src={upcomingMatch.team2.teamLogo}
                                alt={`${upcomingMatch.team2.teamName} Logo`}
                                style={{ width: 80, marginLeft: 10 }}
                            />
                        </Link>
                    </div>
                </div>
            </div>
            
            {/* Embed Twitch Stream or Button 
            {upcomingMatch.stream_url && !upcomingMatch.stream_url.trim().toLowerCase().includes("temp") && (
                <div className="container text-center my-4">
                    <h2 className="mb-3">Live Stream</h2>
                    <iframe
                        src={`https://player.twitch.tv/?channel=${getTwitchChannel(upcomingMatch.stream_url)}&parent=${window.location.hostname}`}
                        height="480"
                        width="854"
                        frameBorder="0"
                        allowFullScreen
                        scrolling="no"
                        title="Twitch Stream"
                    ></iframe>
                    <div className="mt-3">
                        <button
                            className="btn btn-primary"
                            onClick={() => window.open(upcomingMatch.stream_url, "_blank", "noopener,noreferrer")}
                        >
                            Open Stream in New Tab
                        </button>
                    </div>
                </div>
            )}
                */}

            <div className="team-lineups text-center">
                <h2 className="text-center mb-4">Lineups</h2>
                <div className="mb-5">
                    <TeamLineupPre matchId={matchId!} team={upcomingMatch.team1} players={team1Players} />
                </div>
                <div>
                    <TeamLineupPre matchId={matchId!} team={upcomingMatch.team2} players={team2Players} />
                </div>
            </div>
        </div>
    );
};

export default PreMatchPage;
