import { FC, memo } from 'react';
import PlayerImage from '../../../components/PlayerImage';
import { Account } from '../../../models/Account';
import './PlayerInfo.css';
import { divisionMappings, trophyUsers } from '../../../Constants';
import { Link } from 'react-router-dom';
import { Team } from '../../../models/Team';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Tooltip from '@mui/material/Tooltip';

interface PlayerInfoProps {
    playerData: Account | null;
    teamData: Team | null | undefined;
}

const PlayerInfo: FC<PlayerInfoProps> = memo(({ playerData, teamData }) => {
    if (!playerData) {
        return <h1 className='text-center'>Der skete en fejl</h1>;
    }

    const hasTrophy = trophyUsers.includes(playerData.steamID);

    return (
        <div>
            {playerData.lft && (
                <h2 className='text-center'>{playerData.playerName} søger hold!</h2>
            )}
            <div className='player-info-container'>
                <div className="player-card">
                    <div className="player-photo">
                        <PlayerImage id={playerData.steamID} />
                    </div>
                    <div className="player-content">
                        <div className="player-info">
                            <h2 style={{ display: 'inline-flex', alignItems: 'center', gap: 4 }}>
                                {playerData.playerName}
                                {playerData.premium && (
                                    <Tooltip title="Største donator til PowerStats vigtigste person" arrow placement="top">
                                        <span className="badge rounded-pill text-bg-primary" style={{ margin: 0 }}>
                                            <DiamondOutlinedIcon />
                                        </span>
                                    </Tooltip>
                                )}
                                {playerData.donator && (
                                    <Tooltip title="Donator til PowerStats" arrow placement="top">
                                        <span className="badge rounded-pill text-bg-success" style={{ margin: 0 }}>
                                            <MonetizationOnIcon />
                                        </span>
                                    </Tooltip>
                                )}
                                {hasTrophy && (
                                    <Tooltip title="Vinder af en fantasy divsion s27" arrow placement="top">
                                        <span className="badge rounded-pill text-bg-warning" style={{ margin: 0 }}>
                                            <EmojiEventsIcon />
                                        </span>
                                    </Tooltip>
                                )}
                            </h2>

                            <div className="player-details">
                                {playerData.name && <div className="detail"><span>{playerData.name}</span></div>}
                                {playerData.age !== 0 && playerData.age !== null && <div className="detail"><span>{playerData.age} år</span></div>}
                                {playerData.role && <div className="detail"><span>{playerData.role}</span></div>}
                                <div className="detail">
                                    {playerData.faceitElo !== null && <span>{playerData.faceitElo} Elo</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Rest of the component remains the same */}
                {teamData ? (
                    <div className="player-card">
                        <div className="player-photo">
                            <Link to={`/team/${teamData.teamId}`}>
                                <img src={teamData.teamLogo} alt="TeamLogo" />
                            </Link>
                        </div>
                        <div className="player-content">
                            <div className="player-info">
                                <h2>{teamData.teamName}</h2>
                            </div>
                            <div className="player-details">
                                <div className="detail">
                                    <span className="detail-label">Sæson</span>
                                    <span>{teamData.season}</span>
                                </div>
                                <div className="detail">
                                    <span>{divisionMappings.get(teamData.division)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="player-card">
                        <div className="player-photo">
                            <img src="https://www.hltv.org/img/static/team/placeholder.svg" alt="FA" />
                        </div>
                        <div className="player-content">
                            <div className="player-info">
                                <h2>Intet hold registeret denne sæson</h2>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default PlayerInfo;