import { Team } from "../models/Team";
import { Link } from "react-router-dom";
import PlayerImage from "./PlayerImage";
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage
import { PlayerStats } from "../models/PlayerStats";

interface TeamLineupProps {
    matchId: string;
    team: Team;
    players: PlayerStats[];
}

const TeamLineupPre: React.FC<TeamLineupProps> = ({ matchId, team, players }) => {
    const [isDark] = useLocalStorage<boolean>("isDark", false); // Retrieve isDark

    return (
        <div className={`text-center ${isDark ? 'bg-dark text-light' : ''}`}>
            <h2>
                <img src={team.teamLogo} alt="Team logo" className="img-fluid" style={{ width: 35 }} /> {team.teamName}
            </h2>
            <div className="row justify-content-center mx-auto">
                {players.map((player, index) => {
                    console.log("Processing player:", player);
                    if (!player.steamID) {
                        console.warn("Undefined steamId for player:", player);
                    }
                    return (
                        <div className="col-6 col-md-2" key={player.steamID || index}>
                            <Link to={`/player/${player.steamID}`} className="text-decoration-none">
                                <div
                                    className="card mb-3"
                                    style={
                                        isDark
                                            ? {
                                                backgroundColor: index % 2 === 0 ? '#343a40' : '#212529', // Shifting colors
                                                border: '1px solid #111',
                                            }
                                            : undefined
                                    }
                                >
                                    <div className="card-body text-center">
                                        <PlayerImage id={player.steamID} width={75} />
                                        <p className={`mt-2 ${isDark ? 'text-light' : 'text-dark'}`}>
                                            {player.account ? player.account.playerName : player.steamID}
                                        </p>
                                        <p className={`mb-0 ${isDark ? 'text-light' : 'text-dark'}`}>
                                            Current Rating: {player.hltv2Rating.toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TeamLineupPre;
