import { useNavigate, useParams } from "react-router-dom";
import { Account } from "../../models/Account";
import { fetchAccount, updateAccount } from "../../services/AccountService";
import { useState, useEffect, useContext } from "react";
import '../../styles/theme.css';
import { AuthContext } from "../../context/AuthContext";
import { admins, roles } from "../../Constants";

const EditPlayerPage = () => {
    const { steamId } = useParams();
    const { user: visitor, token } = useContext(AuthContext);
    const [player, setPlayer] = useState<Account | null>(null);
    const [editPermission, setEditPermission] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [formData, setFormData] = useState<Partial<Account>>({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlayer = async () => {
            if (!steamId) return;
            try {
                const data = await fetchAccount(steamId);
                if (data) {
                    setPlayer(data);
                    // Initialize form data with original player data
                    setFormData(data);
                }
            } catch (error) {
                console.error('Error fetching player data:', error);
                setPlayer(null);
            }
        };

        fetchPlayer();
    }, [steamId]);

    useEffect(() => {
        if (!visitor) return;
        console.log("Debug: Admin check:", {
            visitorSteamID: visitor.steamID,
            isInAdminList: admins.includes(Number(visitor.steamID)),
            adminsList: admins
        });
        if (admins.includes(Number(visitor.steamID))) {
            setIsAdmin(true);
        }
    }, [visitor]);

    useEffect(() => {
        if (!visitor) return;
        if (visitor.steamID === steamId || isAdmin) {
            setEditPermission(true);
        }
    }, [visitor, steamId, isAdmin]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { id, value, type } = e.target;
        const checked = (e.target as HTMLInputElement).checked;

        if (!player) return;

        if (type === 'checkbox') {
            setFormData(prev => ({
                ...prev,
                [id === 'flexSwitchCheckDefault' ? 'lft' : id]: checked
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [id.replace('-input', '').replace('-url', '').replace('-select', '')]: value
            }));
        }
    };

    const validateSocialMediaInputs = (twitter: string, twitch: string, youtube: string, instagram: string) => {
        if (twitter?.includes("twitter.com") || twitter?.includes("x.com")) {
            alert("Indtast venligst kun dit Twitter brugernavn, ikke hele linket.");
            return false;
        }
        if (twitch?.includes("twitch.tv")) {
            alert("Indtast venligst kun dit Twitch brugernavn, ikke hele linket.");
            return false;
        }
        if (youtube?.includes("youtube.com")) {
            alert("Indtast venligst kun dit YouTube brugernavn, ikke hele linket.");
            return false;
        }
        if (instagram?.includes("instagram.com")) {
            alert("Indtast venligst kun dit Instagram brugernavn, ikke hele linket.");
            return false;
        }
        return true;
    }

    const savePlayer = async (updatedData: Account) => {
        try {
            if (!token) {
                alert("Du skal være logget ind for at ændre din side");
                navigate(`/player/${steamId}`);
                return;
            }
            await updateAccount(updatedData, token);
            alert("Dine ændringer er gemt");
            navigate(`/player/${steamId}`);
        }
        catch (error) {
            alert("Der skete en fejl");
        }
    }

    const saveChanges = () => {
        if (!player) {
            console.log("Debug: No player data found");
            return;
        }
    
        console.log("Debug: Current state:", {
            isAdmin,
            visitorSteamID: visitor?.steamID,
            playerSteamID: steamId,
            originalPlayerName: player.name,
            isAdminEditingOther: isAdmin && visitor?.steamID !== steamId
        });
    
        const lft = document.getElementById("flexSwitchCheckDefault") as HTMLInputElement;
        const twitter = document.getElementById("twitter-url") as HTMLInputElement;
        const twitch = document.getElementById("twitch-url") as HTMLInputElement;
        const youtube = document.getElementById("youtube-url") as HTMLInputElement;
        const instagram = document.getElementById("instagram-url") as HTMLInputElement;
        const role = document.getElementById("role-select") as HTMLInputElement;
        const name = document.getElementById("name-input") as HTMLInputElement;
        const age = document.getElementById("age-input") as HTMLInputElement;
        const donator = document.getElementById("donator") as HTMLInputElement;
        const premium = document.getElementById("premium") as HTMLInputElement;
    
        console.log("Debug: Form values:", {
            formName: name.value,
            currentName: player.name
        });
    
        // Create updated player object
        const updatedPlayer = {
            ...player,
            lft: lft.checked,
            twitter: twitter.value || "",
            twitch: twitch.value || "",
            youtube: youtube.value || "",
            instagram: instagram.value || "",
            role: role.value || "",
            age: parseInt(age.value) || 0
        };
    
        console.log("Debug: Before name logic:", {
            isAdmin,
            visitorSteamID: visitor?.steamID,
            profileSteamID: steamId,
            nameInputValue: name.value,
            originalName: player.name
        });
    
        // Only update name if this is not an admin editing someone else's profile
        if (isAdmin && visitor?.steamID !== steamId) {
            console.log("Debug: Admin editing another user - keeping original name");
            updatedPlayer.name = player.name;
        } else {
            console.log("Debug: User editing own profile or admin editing own profile - allowing name update");
            updatedPlayer.name = name.value || "";
        }
    
        console.log("Debug: Final player object:", {
            finalName: updatedPlayer.name,
            originalName: player.name,
            isNameChanged: updatedPlayer.name !== player.name
        });
    
        // Admin-only updates
        if (isAdmin) {
            updatedPlayer.donator = donator.checked;
            updatedPlayer.premium = premium.checked;
        }
    
        if (!validateSocialMediaInputs(updatedPlayer.twitter, updatedPlayer.twitch, updatedPlayer.youtube, updatedPlayer.instagram)) {
            return;
        }
    
        if (updatedPlayer.lft && !updatedPlayer.role && !updatedPlayer.age) {
            alert("Du skal vælge en rolle og skrive din alder");
            return;
        }
    
        console.log("Debug: Sending update request with data:", updatedPlayer);
        savePlayer(updatedPlayer);
    }

    if (!player) {
        return <div><h1>Ændre din side</h1><p>Der skete en fejl</p></div>;
    }

    if (!editPermission) {
        return <div><h1>Ændre din side</h1><p>Du har ikke adgang til denne side</p></div>;
    }

    return (
        <div className="container">
            <h1>Ændre din side</h1>
            <hr />
            <div className="row">
                <div className="col-12 col-md-6">
                    <h2>Konto indstillinger</h2>
                    <div className="mb-3">
                        <h3>LFT</h3>
                        <div className="form-check form-switch">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id="flexSwitchCheckDefault" 
                                checked={formData.lft ?? player.lft} 
                                onChange={handleChange} 
                            />
                        </div>
                        <h3>Ændre rolle</h3>
                        <div className="mb-3">
                            <select 
                                className="form-select" 
                                id="role-select" 
                                value={formData.role ?? player.role ?? ""}
                                onChange={handleChange}
                            >
                                <option value="" disabled>Vælg en rolle</option>
                                {roles.map((role) => (
                                    <option value={role} key={role}>{role}</option>
                                ))}
                            </select>
                        </div>
                        {isAdmin && (
                            <div className="mb-3">
                                <h3>Donator</h3>
                                <div className="form-check form-switch">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="donator" 
                                        checked={formData.donator ?? player.donator}
                                        onChange={handleChange}
                                    />
                                </div>
                                <h3>Premium</h3>
                                <div className="form-check form-switch">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="premium" 
                                        checked={formData.premium ?? player.premium}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <h2>Personlige oplysninger</h2>
                    <div className="mb-3">
                        <h3>Navn</h3>
                        <div className="mb-3">
                            <input 
                                type="text" 
                                className="form-control" 
                                id="name-input"
                                value={formData.name ?? player.name ?? ""}
                                onChange={handleChange}
                            />
                        </div>
                        <h3>Alder</h3>
                        <div className="mb-3">
                            <input 
                                type="number" 
                                className="form-control" 
                                id="age-input"
                                value={formData.age ?? player.age ?? ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="col-12 col-md-6">
                    <h2>Sociale medier</h2>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text">https://twitter.com/</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="twitter-url"
                                value={formData.twitter ?? player.twitter ?? ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text">https://www.twitch.tv/</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="twitch-url"
                                value={formData.twitch ?? player.twitch ?? ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text">https://www.youtube.com/@</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="youtube-url"
                                value={formData.youtube ?? player.youtube ?? ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="input-group">
                            <span className="input-group-text">https://www.instagram.com/</span>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="instagram-url"
                                value={formData.instagram ?? player.instagram ?? ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <h2>Gem ændringer</h2>
            <div className="mb-3">
                <button type="button" className="btn btn-primary" onClick={saveChanges}>Gem ændringer</button>
            </div>
        </div>
    );
}

export default EditPlayerPage;