import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getTopPicksByDivision } from "../../services/FantasyService";
import { Account } from "../../models/Account";
import Loading from "../../components/Loading";
import { divisionMappingsSimpled, currentSeason } from "../../Constants";

interface PickedPlayer {
    account: Account;
    picks: number;
}

const DetailedPicksPage = () => {
    const { season: seasonParam } = useParams<{ season?: string }>();
    // Use season from URL params or fallback to currentSeason
    const season = seasonParam ? parseInt(seasonParam, 10) : currentSeason;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [division, setDivision] = useState<string>('all'); // Default to 'all'
    const [pickedPlayers, setPickedPlayers] = useState<PickedPlayer[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(''); // Search input state
    const [filteredPlayers, setFilteredPlayers] = useState<PickedPlayer[]>([]); // Filtered players list

    useEffect(() => {
        const fetchTopPicks = async () => {
            setIsLoading(true);
            try {
                let response: PickedPlayer[] = [];
                
                if (division === 'all') {
                    // Fetch picks for all divisions by making parallel API calls
                    const divisionKeys = Array.from(divisionMappingsSimpled.keys());
                    const promises = divisionKeys.map((div) => getTopPicksByDivision(season, div));
                    const results = await Promise.all(promises);

                    // Combine results from all divisions
                    response = results.flat();
                } else {
                    // Fetch picks for the selected division
                    response = await getTopPicksByDivision(season, division);
                }

                // Sort the players by number of picks in descending order
                response.sort((a, b) => b.picks - a.picks);

                setPickedPlayers(response);
                setFilteredPlayers(response); // Initially, set filtered players to all players
            } catch (err) {
                setError(err instanceof Error ? err.message : String(err));
            } finally {
                setIsLoading(false);
            }
        };

        fetchTopPicks();
    }, [season, division]);

    // Handle search query changes
    useEffect(() => {
        const filtered = pickedPlayers.filter(player =>
            player.account.playerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            player.account.steamID.includes(searchQuery)
        );
        setFilteredPlayers(filtered);
    }, [searchQuery, pickedPlayers]);

    const renderContent = () => {
        if (isLoading) return <Loading />;
        if (error) return <p>Error: {error}</p>;
        if (filteredPlayers.length === 0) return <p>No players found matching your search.</p>;
    
        return (
            <div className="row">
                {filteredPlayers.map((player, index) => (
                    <div className="col-12 col-md-4" key={player.account.steamID}>
                        <Link 
                            to={`/fantasy/teams-using-player/${player.account.steamID}/${season}`} 
                            className="card mb-3 text-decoration-none"
                        >
                            <div className="card-body text-center">
                                <h5>{index + 1}. {player.account.playerName}</h5>
                                <h6>{player.picks} picks</h6>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="container text-center">
            <h1>Top Picks by Division - Season {season}</h1>
            
            {/* Division selection dropdown */}
            <div className="mb-3">
                <label htmlFor="divisionDropdown">Select Division</label>
                <select
                    id="divisionDropdown"
                    className="form-select"
                    value={division}
                    onChange={(e) => setDivision(e.target.value)}
                >
                    {/* Add "All" as the first option */}
                    <option value="all">All</option>
                    {Array.from(divisionMappingsSimpled.entries()).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                    ))}
                </select>
            </div>

            {/* Search input for player search */}
            <div className="mb-3">
                <label htmlFor="searchInput">Search by Player Name or Steam ID</label>
                <input
                    id="searchInput"
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
                />
            </div>
            
            <hr />
            {renderContent()}
        </div>
    );
};

export default DetailedPicksPage;
